import React from 'react';
import './styles.scss';
import PropTypes from 'prop-types';
import MailForm from "../MailForm/MailForm";

const ContactUsBlock = (props) => {
  return (
    <div className="contact-us-block">
      <div className="contact-us-block__title">{props.title}</div>
      <MailForm name={props.formName} buttonText={props.buttonText} descriptionFieldTitle={props.descriptionFieldTitle} attachmentButton={props.attachmentButton} />
      <div className="contact-us-block__footer">
        <div className="contact-us-block__footer-contacts"><a href="mailto:info@spark.run">info@spark.run</a> <span className="grey">or</span> <a href="tel:+971 50 692 3310">+971 50 692 3310</a></div>
        <div className="contact-us-block__footer-note">Feel free to contact us if you have any further questions</div>
      </div>
    </div>
  );
};

ContactUsBlock.propTypes = {
  title: PropTypes.string.isRequired,
  formName: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  attachmentButton: PropTypes.string,
};

export default ContactUsBlock;
