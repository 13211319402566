import React, {useEffect, useState} from 'react';
import { Tabs, Collapse } from 'antd';
import 'antd/es/tooltip/style/index.css';
import './styles.scss';
import { useHistory, useParams } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { domainPics, previewDevices, industries } from '../../components/images';
import Domains from '../../assets/data/domains';

const { TabPane } = Tabs;
const { Panel } = Collapse;

const DomainPage = (props) => {
  const queryParams = useParams();
  
  const isDesktop = useMediaQuery({ query: '(min-width: 840px)' });
  const [isHovered, setIsHovered] = useState(false);

  const [domain, setDomain] = useState(Domains[0]);
  const [deviceImages, setDeviceImages] = useState([]);
  const [deviceMode, setDeviceMode] = useState('');
  const [deviceSVGs, setDeviceSVGs] = useState([]);
  const [activePanel, setActivePanel] = useState('1');

  const history = useHistory();

  const toggleHover = () => {
    setIsHovered(prevState => !prevState);
  };

  useEffect(() => {
    let queryId = queryParams.id;
    if (!isDesktop)
      window.scrollTo(0, 0);
    if (Domains.some(x => x.id === queryId)) {
      //set domain
      let selectedDomain = Domains.filter(x => x.id === queryId)[0];
      setDomain(selectedDomain);

      //get first device in the list
      const selectedPicsDomain = domainPics.find(x=>x.domain === selectedDomain.id);
      const pics = selectedPicsDomain[Object.keys(selectedPicsDomain)[1]];
      const selectedDevice = Object.keys(pics)[0];

      //get first device images
      let devices = [];
      for (const [key, value] of Object.entries(pics)) {
        devices.push(key);
        if (key === selectedDevice) {
          setDeviceImages(value);
        }
      }

      //get device icons, which will be able to change mode
      const deviceIcons = [];
      for (const device of devices) {
        const svgObject = previewDevices.find(x=>x.name === device);
        deviceIcons.push({...svgObject, key: `tooltip_${svgObject.name}`, visible: false});
      }
      setDeviceSVGs(deviceIcons);

      //set device mode
      const previewDeviceIcon = previewDevices.find(device => device.name === selectedDevice);
      setDeviceMode(previewDeviceIcon.name);
    } else {
      history.push('/#ourcases');
    }
  }, []);

  const getDeviceImages = (mode) => {
    const selectedPicsDomain = domainPics.find(x=>x.domain === domain.id);
    for (const [key, value] of Object.entries(selectedPicsDomain[Object.keys(selectedPicsDomain)[1]])) {
      if (key === mode) {
        setDeviceImages(value);
      }
    }
  };

  const onBackClick = () => {
    if (isDesktop)
      history.push('/#ourcases');
    else {
      if (history.length > 1)
        history.goBack();
      else 
        history.push('/#ourcases');
    }
  };

  const changeMode = (mode) => {
    if (deviceMode === mode) {
      return;
    }
    setDeviceMode(mode);
    getDeviceImages(mode);
  };

  const handleCollapseClick = (activeKey) => {
    if (typeof activeKey === 'undefined') {
      return;
    }
    setActivePanel(activeKey);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });

    // fix iOS 15: safari bug
    setTimeout(() => {
      let height = document.querySelectorAll(`.horizontal-slide__left-side`)[0].clientHeight;
      document.querySelectorAll(`.horizontal-slide__left-side`)[0].style.height = `${height}px`;
    }, 100);
  };

  const onTooltipLeave = (key) => {
    const svgObjs = deviceSVGs.map((svg) => {
      if (svg.key === key) {
      }
      return {...svg, visible: false};
    });
    setDeviceSVGs(svgObjs);
  };

  const onTooltipEnter = (key) => {
    const svgObjs = deviceSVGs.map((svg) => {
      if (svg.key === key) {
        return {...svg, visible: true};
      }
      return {...svg, visible: false};
    });
    setDeviceSVGs(svgObjs);
  };

  return (
    <section className="content static">
      <div className="horizontal-slide domain">
        <div className="back" onClick={onBackClick} onMouseEnter={toggleHover} onMouseLeave={toggleHover}>
          <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.0312 7.01562C15.5749 7.01562 16.0156 7.45634 16.0156 8C16.0156 8.54366 15.5749
             8.98438 15.0312 8.98438H3.82812L8.70754 13.9048C9.0939 14.2944 9.09258 14.923 8.7046
              15.311C8.31546 15.7002 7.68454 15.7002 7.2954 15.311L0.691481 8.70711C0.300957 8.31658
               0.300958 7.68342 0.691482 7.29289L7.2954 0.688971C7.68454 0.299833 8.31546 0.299833 8.7046
                0.688971C9.09258 1.07696 9.0939 1.7056 8.70754 2.09521L3.82812 7.01562H15.0312Z" fill={!isHovered ? '#fff' : '#F26D6B'}/>
          </svg>
        </div>
        <div className="horizontal-slide__left-side domain-info">
          <div className="domain-info__wrapper">
            <div className="domain-info__title">{domain.title}</div>
            {isDesktop && <Tabs defaultActiveKey="1" className="tabs">
              <TabPane tab="PROJECT INFO" key="1">
                <div className="domain-tab">
                  <div className="domain-tab__section">
                    <div className="domain-tab__title">Overview</div>
                    <div className="domain-tab__main-text">
                      <p>{domain.projectInfo.overview.main}</p>
                      {domain.projectInfo.overview.topics && domain.projectInfo.overview.topics.map(topic => <div className="topic">
                        <div className="topic-title">{topic.title}</div>
                        <div className="topic-text">{topic.text}</div>
                      </div>)}
                    </div>
                  </div>
                  <div className="domain-tab__section">
                    <div className="domain-tab__title">Industry</div>
                    <div className="domain-tab__main-text icon-list">
                        {domain.projectInfo.industry && domain.projectInfo.industry.map(industry => {
                          const Svg = industries.find(x=>x.title === industry).svg;
                          return (<div className="domain-tab__main-text--iconic">
                            <Svg />
                            <span>{industry}</span>
                          </div>);
                          }
                        )}
                    </div>
                  </div>
                  <div className="domain-tab__section">
                    <div className="domain-tab__title">Techs</div>
                    <div className="domain-tab__main-text">
                      <div className="domain-tab__main-text--tags">
                        {
                          domain.projectInfo.techs && domain.projectInfo.techs.map(tech => <span key={`${domain.id}_${tech}`}>{tech}</span>)
                        }
                      </div>
                    </div>
                  </div>
                  <div className="domain-tab__section">
                    <div className="domain-tab__title">Results</div>
                    <div className="domain-tab__main-text">
                      {domain.projectInfo.results && domain.projectInfo.results.map(result => <p className="margined">
                        {result}
                      </p>)}
                    </div>
                  </div>
                </div>
              </TabPane>
              <TabPane tab="CHALLENGES" key="2">
                <div className="domain-tab">
                  {
                    domain.challenges && domain.challenges.map((challenge, index) =>
                      <div key={`${domain.id}_challenge_${index}`} className="domain-tab__section">
                        <div className="domain-tab__title">{challenge.title}</div>
                        <div className="domain-tab__main-text">
                          {challenge.text}
                        </div>
                      </div>)
                  }
                </div>
              </TabPane>
            </Tabs>}
            {!isDesktop && <Collapse accordion className="collapse" activeKey={activePanel} ghost onChange={handleCollapseClick}>
              <Panel header="Project info" className="custom-collapse-panel" key="1" showArrow={false}>
                <div className="domain-tab">
                  <div className="domain-tab__section">
                    <div className="domain-tab__title">Overview</div>
                    <div className="domain-tab__main-text">
                      <p>{domain.projectInfo.overview.main}</p>
                      {domain.projectInfo.overview.topics && domain.projectInfo.overview.topics.map(topic => <div className="topic">
                        <div className="topic-title">{topic.title}</div>
                        <div className="topic-text">{topic.text}</div>
                      </div>)}
                    </div>
                  </div>
                  <div className="domain-tab__section">
                    <div className="domain-tab__title">Industry</div>
                    <div className="domain-tab__main-text icon-list">
                      {domain.projectInfo.industry && domain.projectInfo.industry.map(industry => {
                          const Svg = industries.find(x=>x.title === industry).svg;
                          return (<div className="domain-tab__main-text--iconic">
                            <Svg />
                            <span>{industry}</span>
                          </div>);
                        }
                      )}
                    </div>
                  </div>
                  <div className="domain-tab__section">
                    <div className="domain-tab__title">Techs</div>
                    <div className="domain-tab__main-text">
                      <div className="domain-tab__main-text--tags">
                        {
                          domain.projectInfo.techs && domain.projectInfo.techs.map(tech => <span key={`${domain.id}_${tech}`}>{tech}</span>)
                        }
                      </div>
                    </div>
                  </div>
                  <div className="domain-tab__section">
                    <div className="domain-tab__title">Results</div>
                    <div className="domain-tab__main-text">
                      {domain.projectInfo.results && domain.projectInfo.results.map(result => <p className="margined">
                        {result}
                      </p>)}
                    </div>
                  </div>
                </div>
              </Panel>
              <Panel header="Challenges" className="custom-collapse-panel" showArrow={false} key="2">
                <div className="domain-tab">
                  {
                    domain.challenges && domain.challenges.map((challenge, index) =>
                      <div key={`${domain.id}_challenge_${index}`} className="domain-tab__section">
                        <div className="domain-tab__title">{challenge.title}</div>
                        <div className="domain-tab__main-text">
                          {challenge.text}
                        </div>
                      </div>)
                  }
                </div>
              </Panel>
            </Collapse>}
          </div>
        </div>
        <div className="horizontal-slide__right-side domain-description">
            <div className="domain-description__title">Preview</div>
            <div className="domain-description__devices">
              {
                deviceSVGs && deviceSVGs.map(svgObj => {
                  const SVG = svgObj.name === deviceMode ? svgObj.svg.active : svgObj.svg.inactive;
                  return (
                    <div key={svgObj.key} className="device-item" onClick={() => changeMode(svgObj.name)}
                         onMouseLeave={() => onTooltipLeave(svgObj.key)}
                         onMouseEnter={() => onTooltipEnter(svgObj.key)}>
                      <SVG />
                      {isDesktop && <div className="tooltip" style={{'display': `${svgObj.visible ? 'block' : 'none'}`}}>
                        <div className="tooltip-content">{svgObj.name}</div>
                      </div>}
                    </div>);
                })
              }
            </div>
          {
            props.images[domain.id][deviceMode] && props.images[domain.id][deviceMode].map((img, index) => <div key={`${domain.id}_pic_${index}`} className="domain-description__img">
              {img}
            </div>)
          }
          </div>
      </div>
    </section>
  );};

export default DomainPage;
