import React, {useState, useContext, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {ThemeContext} from '../../ThemeContext';
import {useMediaQuery} from 'react-responsive/src';
import './styles.scss';
import {Button} from "antd";

let pageHeight = null;

const MobileMenu = ({fillColor}) => {
    const context = useContext(ThemeContext);
    const [showMenu, setShowMenu] = useState(false);
    const notManualMenu = useMediaQuery({query: '(min-width: 840px)'});
    const history = useHistory();

    const [isHovered, setIsHovered] = useState(false);

    const scrolling = () => {
        if (!notManualMenu) {
            setIsHovered(true);
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', scrolling);
        pageHeight = !pageHeight ? window.innerHeight : pageHeight;
        scrolling();

        return () => {
            window.removeEventListener('scroll', scrolling);
        }
    });

    const toggleHover = () => {
        setIsHovered(prevState => !prevState);
    };


    const toggleShowMenu = () => {
        if (!showMenu)
            hideScroll();
        else 
            showScroll();
        setShowMenu(!showMenu);
    };

    const hideScroll = () => {
        let body = document.getElementsByTagName("body").length ? document.getElementsByTagName("body")[0] : null;
        if (body) {
            body.style.overflow = "hidden";
            body.style.height = "100vh";
        }
    }

    const showScroll = () => {
        let body = document.getElementsByTagName("body").length ? document.getElementsByTagName("body")[0] : null;
        if (body) {
            body.style.overflow = "scroll";
            body.style.height = "auto";
        }
    }

    const moveToDetails = () => {
        toggleShowMenu();
        history.push('/contacts/contactus');
    };

    const goTo = (name) => {
        window.location.href = `#${name}`;
        window.scrollTo(0, window.scrollY - 50);
        toggleShowMenu();
    }

    return (
        <div className="mobile-menu" x-color={fillColor}>
            <div className="mobile-menu-icon" onClick={toggleShowMenu} onMouseEnter={toggleHover} onMouseLeave={toggleHover}>
                <svg width="18" height="16" viewBox="0 0 18 16" fill={isHovered ? context.hoverText : fillColor} xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M-3.7794e-08 0.864624C-1.69159e-08 0.386991 0.435389 -7.67774e-07 0.972761 -7.44284e-07L17.0272 -4.25205e-08C17.5646 -1.90313e-08 18 0.386992 18 0.864625C18 1.34226 17.5646 1.72924 17.0272 1.72924L0.972761 1.72924C0.435388 1.72924 -5.8672e-08 1.34226 -3.7794e-08 0.864624ZM-3.4388e-07 7.86707C-3.23002e-07 7.38943 0.435388 7.00244 0.972761 7.00244L17.0272 7.00244C17.5646 7.00244 18 7.38943 18 7.86707C18 8.3447 17.5646 8.73169 17.0272 8.73169L0.972761 8.73168C0.435388 8.73168 -3.64758e-07 8.3447 -3.4388e-07 7.86707ZM0.972761 14.0024C0.435388 14.0024 -6.28982e-07 14.3894 -6.4986e-07 14.8671C-6.70738e-07 15.3447 0.435388 15.7317 0.97276 15.7317L17.0272 15.7317C17.5646 15.7317 18 15.3447 18 14.8671C18 14.3894 17.5646 14.0024 17.0272 14.0024L0.972761 14.0024Z"/>
                </svg>
            </div>
            {showMenu && <div className="popup">
                <div className="menu">
                    <div className="close-icon" onClick={() => toggleShowMenu()}>
                        <svg width="18" height="18" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M13.2812 0.71875C13.6696 1.10708 13.6696 1.73667 13.2812 2.125L8.40625 7L13.2812 11.875C13.6696 12.2633 13.6696 12.8929 13.2812 13.2812C12.8929 13.6696 12.2633 13.6696 11.875 13.2812L7 8.40625L2.125 13.2812C1.73668 13.6696 1.10708 13.6696 0.71875 13.2812C0.330425 12.8929 0.330425 12.2633 0.71875 11.875L5.59375 7L0.71875 2.125C0.330425 1.73668 0.330425 1.10708 0.71875 0.71875C1.10708 0.330425 1.73667 0.330425 2.125 0.71875L7 5.59375L11.875 0.71875C12.2633 0.330425 12.8929 0.330425 13.2812 0.71875Z"
                                fill="#0E0D0D"/>
                        </svg>
                    </div>
                    <ul className="menu-list">
                        <li className="menu-item" data-menuanchor="whoweare"><a onClick={() => goTo("whoweare")}>Who we are</a></li>
                        <li className="menu-item" data-menuanchor="ourcases"><a onClick={() => goTo("ourcases")}>Our cases</a></li>
                        <li className="menu-item" data-menuanchor="howwework"><a onClick={() => goTo("howwework")}>How we
                            work</a></li>
                        <li className="menu-item" data-menuanchor="company"><a onClick={() => goTo("company")}>Company</a>
                        </li>
                        <li className="menu-item" data-menuanchor="contacts"><a onClick={() => goTo("contacts")}>Contacts</a>
                        </li>
                    </ul>
                    <div className="btn-container">
                        <Button onClick={moveToDetails} className="contact-us-btn">CONTACT US</Button>
                    </div>

                    { false
                        ?   <div className="link-instagram">
                                <a href="https://www.instagram.com/spark_itcompany/" target="_blank" rel="noopener noreferrer">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd"
                                            d="M17.13 1.67C15.79 1.61 15.39 1.6 12 1.6C8.61 1.6 8.21 1.61 6.87 1.67C3.43 1.83 1.83 3.46 1.67 6.87C1.61 8.21 1.6 8.61 1.6 12C1.6 15.39 1.61 15.79 1.67 17.13C1.83 20.53 3.42 22.17 6.87 22.33C8.21 22.39 8.61 22.4 12 22.4C15.39 22.4 15.79 22.39 17.13 22.33C20.57 22.17 22.17 20.54 22.33 17.13C22.39 15.79 22.4 15.39 22.4 12C22.4 8.61 22.39 8.21 22.33 6.87C22.17 3.46 20.57 1.83 17.13 1.67ZM7.05 0.07C8.33 0.01 8.74 0 12 0C15.26 0 15.67 0.01 16.95 0.07C21.31 0.27 23.73 2.7 23.93 7.05C23.99 8.33 24 8.74 24 12C24 15.26 23.99 15.67 23.93 16.95C23.73 21.31 21.3 23.73 16.95 23.93C15.67 23.99 15.26 24 12 24C8.74 24 8.33 23.99 7.05 23.93C2.69 23.73 0.27 21.31 0.07 16.95C0.01 15.67 0 15.26 0 12C0 8.74 0.01 8.33 0.07 7.05C0.27 2.69 2.7 0.27 7.05 0.07ZM20 5.2C20 5.86274 19.4627 6.4 18.8 6.4C18.1373 6.4 17.6 5.86274 17.6 5.2C17.6 4.53726 18.1373 4 18.8 4C19.4627 4 20 4.53726 20 5.2ZM12 18.4C15.5346 18.4 18.4 15.5346 18.4 12C18.4 8.46538 15.5346 5.6 12 5.6C8.46538 5.6 5.6 8.46538 5.6 12C5.6 15.5346 8.46538 18.4 12 18.4ZM11.9999 16.8C14.6509 16.8 16.7999 14.651 16.7999 12C16.7999 9.34903 14.6509 7.19999 11.9999 7.19999C9.34894 7.19999 7.1999 9.34903 7.1999 12C7.1999 14.651 9.34894 16.8 11.9999 16.8Z"
                                            fill="#F26D6B"/>
                                    </svg>
                                </a>
                            </div>
                        :   null
                    }
                </div>
            </div>}
        </div>
    )
}

export default MobileMenu;
