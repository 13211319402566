import React, { useEffect } from 'react';
import {Switch, BrowserRouter as Router, Route, useLocation} from 'react-router-dom';
import './App.css';
import PageComposer from './pages/PageComposer';
import VacancyPage from './pages/VacancyPage/VacancyPage';
import ContactsDetailsPage from './pages/ContacsDetailPage/ContactsDetailPage';
import DomainPage from './pages/DomainPage/DomainPage';
import TimeAndMaterialsPage from './pages/TimeAndMaterialsPage/TimeAndMaterialsPage';
import SoftwareProductDevelopment from "./pages/SoftwareProductDevelopment/SoftwareProductDevelopment";
import {useMediaQuery} from "react-responsive/src";
import { domainPics } from "./components/images";

let imgs = {};
for (let domain of domainPics) {
    imgs[domain.domain] = {};
    for (let type in domain.pics) {
        imgs[domain.domain][type] = [];
        for (let image of domain.pics[type]) {
            imgs[domain.domain][type].push(<img src={image} alt={`${domain.domain} picture`} draggable={false}/>);
        }
    }
}

const windowHeight = window.innerHeight;

const setPageHeight = (isDesktop) => {
    if (!isDesktop)
        document.querySelectorAll(".section").forEach(dom => dom.style.minHeight = `${windowHeight}px`);
}

const AppSwitch = () => {
    const isDesktop = useMediaQuery({ query: '(min-width: 768px)' });

    let location = useLocation();

    useEffect(() => {
        setPageHeight(isDesktop);
    });

    return (
        <Switch>
            <Route exact path={'/company/vacancy/:id'} component={VacancyPage}/>
            <Route exact path={'/contacts/contactus'} component={ContactsDetailsPage}/>
            <Route exact path={'/ourcases/:id'}>
                <DomainPage images={imgs} />
            </Route>
            <Route exact path={'/howwework/dedicatedteam'} component={TimeAndMaterialsPage}/>
            <Route exact path={'/howwework/softwareproductdevelopment'} component={SoftwareProductDevelopment}/>
            <Route path={'/'} component={PageComposer}/>
        </Switch>
    )
}

const App = () => {
    return (
        <div className="App">
            <Router>
                <AppSwitch />
            </Router>
            {
                Object.values(imgs).map(x => <div style={{display: "none"}}>{Object.values(x).map(y => y.map(z => z))}</div>)
            }
        </div>
    );
}

export default App;
