import React, {useContext, useState, useRef, useEffect} from 'react';
import {SocialLinks} from '../SocialLinks/SocialLinks';
import Menu from './Menu/Menu';
import {ThemeContext} from '../ThemeContext';
import MobileMenu from './MobileMenu/MobileMenu';
import './styles.scss';
import {useMediaQuery} from 'react-responsive/src';
import packageJson from "../../../package.json";

let pageHeight = null;
let timerId= null;

const Header = (props) => {
    const isDesktop = useMediaQuery({query: '(min-width: 840px)'});
    const isTablet = useMediaQuery({minWidth: 841, maxWidth: 1024});
    const isLess1340 = useMediaQuery({query: '(max-width: 1340px)'});
    const notManualMenu = useMediaQuery({query: '(min-width: 840px)'});
    const context = useContext(ThemeContext);

    const [isHovered, setIsHovered] = useState(false);
    const [bg, setBg] = useState("");

    const scrolling = () => {
        if (!notManualMenu) {
            setIsHovered(true);
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', scrolling);
        pageHeight = !pageHeight ? window.innerHeight : pageHeight;
        scrolling();

        return () => {
            window.removeEventListener('scroll', scrolling);
        }
    });

    const toggleHover = () => {
        setIsHovered(prevState => !prevState);
    };

    const moveToTop = () => {
        if (props && props.api && props.api.current !== null) {
            props.api.current.fullpageApi.moveTo(1, 0);
        }
        else {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    };

    const showVersionDown = () => {
        timerId = setTimeout(() => {
            if (timerId) {
                timerId = null;
                console.log("Application version:", packageJson.version);
                window.location.hash = `#v.${packageJson.version}`;
            }
        }, 500);
    }

    const showVersionUp = () => {
        if (timerId)
            clearTimeout(timerId);
        timerId = null;
    }

    return (
        <section className={`header ${bg}`}>
            <div className="left-column" onMouseDown={showVersionDown} onMouseUp={showVersionUp} onMouseOut={showVersionUp}>
                {isDesktop &&
                <div className="spark">
                    <svg onMouseEnter={toggleHover} onMouseLeave={toggleHover} onClick={moveToTop} width="117"
                         height="16" viewBox="0 0 117 16" fill={isHovered ? context.hoverText : context.text}
                         xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M0.865373 15.9999C0.548112 15.9999 0.243054 15.8247 0.091396 15.5214C-0.122145 15.0943 0.0513026 14.5748 0.478384 14.3613L13.2011 7.9995L0.478384 1.6386C0.119287 1.45905 -0.0689776 1.05637 0.0234115 0.66677C0.114929 0.276296 0.463567 0 0.864501 0H15.1343C15.6119 0 15.9989 0.386988 15.9989 0.864623C15.9989 1.34226 15.6119 1.72925 15.1343 1.72925H4.52782L15.5212 7.22552C15.8141 7.37195 15.9989 7.67178 15.9989 7.99863C15.9989 8.32635 15.8141 8.62531 15.5212 8.77174L1.25149 15.9084C1.12772 15.9702 0.99524 15.9999 0.865373 15.9999Z"
                            fill={isHovered ? context.hoverText : context.text}/>
                        <path
                            d="M27.4151 15.999C27.3036 15.999 27.1911 15.9772 27.0848 15.9328C26.7614 15.7985 26.5514 15.4839 26.5514 15.1335V0.864623C26.5514 0.386988 26.9384 0 27.416 0H41.6858C42.0353 0 42.3508 0.210926 42.485 0.533416C42.6192 0.856778 42.5452 1.22808 42.2976 1.47561L28.027 15.7462C27.8614 15.9118 27.64 15.999 27.4151 15.999ZM28.2798 1.72925V13.0469L39.5974 1.72925H28.2798Z"
                            fill={isHovered ? context.hoverText : context.text}/>
                        <path
                            d="M46.8302 15.9999C46.6786 15.9999 46.5252 15.9598 46.3857 15.8761C45.9761 15.6303 45.8436 15.0995 46.0894 14.6899L49.5069 8.99486C49.5121 8.98615 49.5174 8.97656 49.5235 8.96784L54.6519 0.420109C54.8079 0.159502 55.0895 0 55.3937 0C55.6978 0 55.9785 0.159502 56.1354 0.420109L64.6971 14.6899C64.8888 15.0097 64.8539 15.4176 64.6099 15.7C64.3659 15.9824 63.9675 16.0766 63.6233 15.9328L50.6138 10.5123L47.5737 15.5798C47.4098 15.85 47.124 15.9999 46.8302 15.9999ZM51.5116 9.01317L61.86 13.3249L55.3928 2.54593L51.5116 9.01317Z"
                            fill={isHovered ? context.hoverText : context.text}/>
                        <path
                            d="M74.8078 15.999C74.6963 15.999 74.5838 15.9772 74.4775 15.9328C74.1541 15.7985 73.9441 15.4839 73.9441 15.1335V0.864623C73.9441 0.386988 74.3311 0 74.8087 0H89.0785C89.428 0 89.7435 0.210926 89.8777 0.533416C90.0119 0.856778 89.9379 1.22808 89.6903 1.47561L75.4197 15.7462C75.2541 15.9118 75.0327 15.999 74.8078 15.999ZM75.6725 1.72925V13.0469L86.9901 1.72925H75.6725Z"
                            fill={isHovered ? context.hoverText : context.text}/>
                        <path
                            d="M89.0776 16C88.9477 16 88.8161 15.9703 88.6915 15.9084L79.178 11.1521C78.7509 10.9386 78.5775 10.4191 78.791 9.99206C79.0046 9.56497 79.5241 9.39153 79.9511 9.60507L89.4646 14.3614C89.8917 14.5749 90.0651 15.0944 89.8516 15.5215C89.6999 15.8248 89.3949 16 89.0776 16Z"
                            fill={isHovered ? context.hoverText : context.text}/>
                        <path
                            d="M101.359 15.999C100.881 15.999 100.494 15.612 100.494 15.1344V0.864623C100.494 0.386988 100.881 0 101.359 0C101.836 0 102.223 0.386988 102.223 0.864623V15.1344C102.223 15.612 101.836 15.999 101.359 15.999Z"
                            fill={isHovered ? context.hoverText : context.text}/>
                        <path
                            d="M115.628 16C115.498 16 115.366 15.9704 115.241 15.9085L100.972 8.7736C100.679 8.62717 100.494 8.32734 100.494 8.0005C100.494 7.67278 100.679 7.37382 100.972 7.22739L115.241 0.0916401C115.668 -0.121901 116.188 0.0515467 116.402 0.478628C116.615 0.90571 116.442 1.42518 116.015 1.63872L103.292 7.99962L116.015 14.3614C116.442 14.5749 116.615 15.0944 116.402 15.5215C116.25 15.8248 115.945 16 115.628 16Z"
                            fill={isHovered ? context.hoverText : context.text}/>
                    </svg>
                </div>
                }
                {!isDesktop && <div className="spark-small" x-context={context.text} onTouchStart={showVersionDown} onTouchEnd={showVersionUp} onTouchCancel={showVersionUp}>
                    <svg onMouseEnter={toggleHover} onMouseLeave={toggleHover} onClick={moveToTop} width="16"
                         height="16" viewBox="0 0 16 16" fill={context.text} xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M0.865373 15.9999C0.548112 15.9999 0.243054 15.8247 0.091396 15.5214C-0.122145 15.0943 0.0513026 14.5748 0.478384 14.3613L13.2011 7.9995L0.478384 1.6386C0.119287 1.45905 -0.0689776 1.05637 0.0234115 0.66677C0.114929 0.276296 0.463567 0 0.864501 0H15.1343C15.6119 0 15.9989 0.386988 15.9989 0.864623C15.9989 1.34226 15.6119 1.72925 15.1343 1.72925H4.52782L15.5212 7.22552C15.8141 7.37195 15.9989 7.67178 15.9989 7.99863C15.9989 8.32635 15.8141 8.62531 15.5212 8.77174L1.25149 15.9084C1.12772 15.9702 0.99524 15.9999 0.865373 15.9999Z"
                            fill={isHovered ? context.hoverText : context.text}/>
                    </svg>
                </div>}
            </div>

            <div className="center-column">
                <Menu classname="white"/>
            </div>

            <div className="right-column">
                {isDesktop && <SocialLinks fillColor={context.text}/>}
                {isLess1340 && <MobileMenu fillColor={context.text}/>}
            </div>

        </section>
    );
};

export default Header;
