import React from 'react';


export const themes = {
  light: {
    text: '#F26D6B',
    hoverText: '#1D1B1B',
    background: '#fff',
  },
  dark: {
    text: '#fff',
    hoverText: '#F26D6B',
    background: '#0E0D0D',
  },
};

export const ThemeContext = React.createContext(themes.dark);
