import React, { useRef, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Landing from './Home/Landing';
import WhatWeDo from './WhatWeDo/WhatWeDo';
import ReactFullpage from '@fullpage/react-fullpage';
import Contacts from './Contacts/Contacts';
import Career from './Career/Career';
import HowWeWork from './HowWeWork/HowWeWork';
import Header from '../components/Header/Header';
import Footer from '../components/Footer';
import {ThemeContext, themes} from '../components/ThemeContext';
import {useMediaQuery} from 'react-responsive/src';

const PageComposer = () => {
    const [theme, setTheme] = useState(themes.dark);
    const [isBottom, setIsBottom] = useState(false);
    const location = useLocation();
    const background = useRef(null);
    const fullpageRef = useRef(null);
    const isDesktop = useMediaQuery({ query: '(min-width: 840px)' });
    const [isLoaded, setIsLoaded] = useState(false);

    const getHided = () => window.innerHeight < 840;

    const [isHided, setIsHided] = useState(false);

    const blackLayout = ['whoweare', 'company'];
    const whiteLayout = ['mission&vision', 'ourcases', 'howwework', 'contacts'];

    useEffect(() => {
        if (whiteLayout.some(x => x === location.hash.replace('#', ''))) {
            setTheme(themes.light);
            background.current.setAttribute('style', ' background-color: #fff;');
        } else if (blackLayout.some(x => x === location.hash.replace('#', ''))){
            setTheme(themes.dark);
            background.current.setAttribute('style', ' background-color: #0E0D0D;');
        }

        setIsHided(getHided);

        const resizeListener = () => {
            setIsHided(getHided());
            if (fullpageRef.current !== null) {
                fullpageRef.current.fullpageApi.reBuild();
            }
        };

        window.addEventListener('resize', resizeListener);
        
        return () => {
            window.removeEventListener('resize', resizeListener);
        };

    }, []);

    const handlePageChange = (origin, destination, direction) => {
        if (destination.anchor === 'contacts') {
            setIsBottom(true);
        } else if (isBottom) {
            setIsBottom(false);
        }
        if (background.current === null) {
            return;
        }
        if (whiteLayout.some(x => x === destination.anchor)) {
            setTheme(themes.light);
            background.current.setAttribute('style', ' background-color: #fff; transition: background-color 400ms linear;');
        } else if (blackLayout.some(x => x === destination.anchor)){
            setTheme(themes.dark);
            background.current.setAttribute('style', ' background-color: #0E0D0D; transition: background-color 400ms linear;');
        } else {
            if (fullpageRef.current !== null) {
                window.location.reload();
            }
        }
    };

    const afterRender = () => {
        setIsLoaded(true);
    }

    if (isDesktop) {
        return (
            <ThemeContext.Provider value={theme}>
                <div className={`colored-wrapper`} ref={background}>
                    <Header api={fullpageRef}/>
                    <ReactFullpage
                        ref={fullpageRef}
                        licenseKey={'5FB635E9-922A44AF-994B661B-1EF7E075'}
                        scrollOverflow={true}
                        scrollingSpeed={700}
                        touchSensitivity={8}
                        anchors={['whoweare', 'ourcases', 'howwework', 'company', 'contacts']}
                        navigation={false}
                        onLeave={handlePageChange}
                        afterRender={afterRender}
                        menu='.header'
                        loopHorizontal={false}
                        controlArrows={false}
                        responsiveWidth={840}
                        autoScrolling={true}
                        animateAnchor={false}
                        render={() => {
                            return (
                                <>
                                    <ReactFullpage.Wrapper>
                                        <Landing/>
                                        <WhatWeDo/>
                                        <HowWeWork/>
                                        <Career/>
                                        <Contacts/>
                                    </ReactFullpage.Wrapper>
                                </>
                            );
                        }}
                    />
                    {isDesktop && !isBottom && !isHided && <Footer/>}
                </div>
            </ThemeContext.Provider>
        );
    }
    else {
        return (
            <div className="colored-wrapper" ref={background}>
                <Header />
                <Landing/>
                <WhatWeDo/>
                <HowWeWork/>
                <Career/>
                <Contacts/>
            </div>
        );
    }
};

export default PageComposer;
