import React, {useEffect} from 'react';
import './styles.scss';
import {useMediaQuery} from "react-responsive/src";

const Landing = () => {
    const isDesktop = useMediaQuery({ query: '(min-width: 840px)' });

    return (
      <div id={isDesktop ? "" : "whoweare"} className="layout black section home">
          <h1 className="hidden">Who we are</h1>
          <section className="content">
            <div className="landing">
              <div className="white filled">Not just developers.</div>
              <div className="white filled">Not just managers.</div>
              <div className="white filled">We are experts.</div>
              <div className="red empty">And we will spark you.</div>
            </div>
          </section>
        </div>
    )};

export default Landing;
