import React from 'react';
import {useHistory} from 'react-router-dom';
import './styles.scss';
import {useMediaQuery} from "react-responsive/src";

const HowWeWork = () => {
  const isDesktop = useMediaQuery({ query: '(min-width: 840px)' });
  const history = useHistory();

  const moveToTimeline = (e) => {
    e.stopPropagation();
    e.preventDefault();
    history.push('/howwework/dedicatedteam');
    return false;
  };

  const moveToSoftwareProductDevelopment = (e) => {
    e.stopPropagation();
    e.preventDefault();
    history.push('/howwework/softwareproductdevelopment');
    return false;
  }

  return (
    <div id={isDesktop ? "" : "howwework"} className="layout white section howwework fp-auto-height-responsive">
      <section className="content">
        <div className="definition-container">
        <div className="definition">
          <div className="icon">
            <svg width="44" height="52" viewBox="0 0 44 52" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M32 1H15V37H43V12L32 1Z" stroke="#F26D6B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M36 41V44H8V8H11" stroke="#F26D6B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M29 48V51H1V15H4" stroke="#F26D6B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M27.4032 23.6003L25.8182 22.0154C25.5283 21.7254 25.0582 21.7254 24.7682 22.0154C24.4782 22.3053 24.4782 22.7754 24.7682 23.0654L26.6862 24.9833C27.0767 25.3739 27.7098 25.3739 28.1004 24.9833L32.8123 20.2714C33.102 19.9817 33.102 19.5121 32.8123 19.2224C32.5222 18.9323 32.0518 18.9328 31.7623 19.2235L27.4032 23.6003ZM33.501 11.761C33.8518 11.3407 34.4769 11.2844 34.8972 11.6352L37.94 14.1746C38.3597 14.5249 38.4161 15.1491 38.066 15.569C37.7158 15.989 37.0914 16.0456 36.6714 15.6954L33.6273 13.1577C33.2065 12.807 33.15 12.1815 33.501 11.761ZM22.802 11.6348C23.2221 11.2842 23.8469 11.3406 24.1975 11.7607C24.5482 12.1809 24.4918 12.8058 24.0715 13.1564L21.0289 15.6946C20.6087 16.0451 19.9839 15.9885 19.6336 15.5681C19.2836 15.1481 19.3401 14.524 19.7598 14.1737L22.802 11.6348ZM28.8494 13.1694C23.9262 13.1694 19.9341 17.1615 19.9341 22.0847C19.9341 27.0079 23.9262 31 28.8494 31C33.7727 31 37.7647 27.0079 37.7647 22.0847C37.7647 17.1615 33.7727 13.1694 28.8494 13.1694ZM28.8494 29.0188C25.0258 29.0188 21.9153 25.9084 21.9153 22.0847C21.9153 18.261 25.0258 15.1506 28.8494 15.1506C32.6731 15.1506 35.7836 18.261 35.7836 22.0847C35.7836 25.9084 32.6731 29.0188 28.8494 29.0188Z" fill="#F26D6B"/>
            </svg>

          </div>
          <div className="list">
            <div className="termin">Dedicated Team</div>
            <div className="text">
              A team of versatile and hand-picked experts, dedicated to you. We will boost your existing team up so you will not need to worry about scaling anymore.
              <a href={`/howwework/dedicatedteam`}>
                <button className="text__to-timeline" onClick={moveToTimeline}>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15.1 19.37L16.1 21.11C15.14 21.55 14.09 21.84 13 21.95V19.93C13.74 19.84 14.44 19.65 15.1 19.37ZM4.07005 13H2.05005C2.16005 14.1 2.45005 15.14 2.89005 16.1L4.63005 15.1C4.35005 14.44 4.16005 13.74 4.07005 13ZM15.1 4.62999L16.1 2.88999C15.14 2.44999 14.1 2.15999 13 2.04999V4.06999C13.74 4.15999 14.44 4.34999 15.1 4.62999ZM19.93 11H21.9501C21.84 9.89999 21.5501 8.85999 21.1101 7.89999L19.3701 8.89999C19.6501 9.55999 19.84 10.26 19.93 11ZM8.90005 19.37L7.90005 21.11C8.86005 21.55 9.91005 21.84 11 21.95V19.93C10.26 19.84 9.56005 19.65 8.90005 19.37ZM11 4.06999V2.04999C9.90005 2.15999 8.86005 2.44999 7.90005 2.88999L8.90005 4.62999C9.56005 4.34999 10.26 4.15999 11 4.06999ZM18.3601 7.16999L20.1 6.15999C19.4701 5.28999 18.7 4.51999 17.83 3.88999L16.82 5.62999C17.41 6.07999 17.9201 6.58999 18.3601 7.16999ZM4.63005 8.89999L2.89005 7.89999C2.45005 8.85999 2.16005 9.89999 2.05005 11H4.07005C4.16005 10.26 4.35005 9.55999 4.63005 8.89999ZM19.93 13C19.84 13.74 19.6501 14.44 19.3701 15.1L21.1101 16.1C21.5501 15.14 21.84 14.09 21.9501 13H19.93ZM16.83 18.36L17.84 20.1C18.71 19.47 19.4801 18.7 20.1101 17.83L18.3701 16.82C17.9201 17.41 17.41 17.92 16.83 18.36ZM7.17005 5.63999L6.17005 3.88999C5.29005 4.52999 4.53005 5.28999 3.90005 6.16999L5.64005 7.17999C6.08005 6.58999 6.59005 6.07999 7.17005 5.63999ZM5.64005 16.83L3.90005 17.83C4.53005 18.7 5.30005 19.47 6.17005 20.1L7.18005 18.36C6.59005 17.92 6.08005 17.41 5.64005 16.83ZM13 6.99999H11V12.41L15.29 16.7L16.7 15.29L13 11.59V6.99999Z" fill="#FFFFFF"/>
                  </svg>
                  <span>Learn more</span>
                </button>
              </a>
            </div>
          </div>
        </div>
        <div className="definition">
          <div className="icon">
            <svg width="41" height="36" viewBox="0 0 41 36" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="1" y="1" width="34" height="28" rx="2" stroke="#F26D6B" stroke-width="2"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M6 5C6 5.55228 5.55228 6 5 6C4.44772 6 4 5.55228 4 5C4 4.44772 4.44772 4 5 4C5.55228 4 6 4.44772 6 5ZM9 5C9 5.55228 8.55228 6 8 6C7.44772 6 7 5.55228 7 5C7 4.44772 7.44772 4 8 4C8.55228 4 9 4.44772 9 5ZM11 6C11.5523 6 12 5.55228 12 5C12 4.44772 11.5523 4 11 4C10.4477 4 10 4.44772 10 5C10 5.55228 10.4477 6 11 6Z" fill="#F26D6B"/>
              <rect x="19" y="14" width="21" height="21" rx="2" fill="white" stroke="#F26D6B" stroke-width="2"/>
              <path d="M25.5 22L23 24.5L25.5 27" stroke="#F26D6B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M33.5 22L36 24.5L33.5 27" stroke="#F26D6B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M28 29L30.5 20.5" stroke="#F26D6B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
          <div className="list">
            <div className="termin">Software Product Development</div>
            <div className="text">
              We will build a market-fit product out of your vision guiding you every step from an idea to a complete solution.
              <a href={`/howwework/softwareproductdevelopment`}>
                <button className="text__to-timeline" onClick={moveToSoftwareProductDevelopment}>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15.1 19.37L16.1 21.11C15.14 21.55 14.09 21.84 13 21.95V19.93C13.74 19.84 14.44 19.65 15.1 19.37ZM4.07005 13H2.05005C2.16005 14.1 2.45005 15.14 2.89005 16.1L4.63005 15.1C4.35005 14.44 4.16005 13.74 4.07005 13ZM15.1 4.62999L16.1 2.88999C15.14 2.44999 14.1 2.15999 13 2.04999V4.06999C13.74 4.15999 14.44 4.34999 15.1 4.62999ZM19.93 11H21.9501C21.84 9.89999 21.5501 8.85999 21.1101 7.89999L19.3701 8.89999C19.6501 9.55999 19.84 10.26 19.93 11ZM8.90005 19.37L7.90005 21.11C8.86005 21.55 9.91005 21.84 11 21.95V19.93C10.26 19.84 9.56005 19.65 8.90005 19.37ZM11 4.06999V2.04999C9.90005 2.15999 8.86005 2.44999 7.90005 2.88999L8.90005 4.62999C9.56005 4.34999 10.26 4.15999 11 4.06999ZM18.3601 7.16999L20.1 6.15999C19.4701 5.28999 18.7 4.51999 17.83 3.88999L16.82 5.62999C17.41 6.07999 17.9201 6.58999 18.3601 7.16999ZM4.63005 8.89999L2.89005 7.89999C2.45005 8.85999 2.16005 9.89999 2.05005 11H4.07005C4.16005 10.26 4.35005 9.55999 4.63005 8.89999ZM19.93 13C19.84 13.74 19.6501 14.44 19.3701 15.1L21.1101 16.1C21.5501 15.14 21.84 14.09 21.9501 13H19.93ZM16.83 18.36L17.84 20.1C18.71 19.47 19.4801 18.7 20.1101 17.83L18.3701 16.82C17.9201 17.41 17.41 17.92 16.83 18.36ZM7.17005 5.63999L6.17005 3.88999C5.29005 4.52999 4.53005 5.28999 3.90005 6.16999L5.64005 7.17999C6.08005 6.58999 6.59005 6.07999 7.17005 5.63999ZM5.64005 16.83L3.90005 17.83C4.53005 18.7 5.30005 19.47 6.17005 20.1L7.18005 18.36C6.59005 17.92 6.08005 17.41 5.64005 16.83ZM13 6.99999H11V12.41L15.29 16.7L16.7 15.29L13 11.59V6.99999Z" fill="#FFFFFF"/>
                  </svg>
                  <span>Learn more</span>
                </button>
              </a>
            </div>
          </div>
        </div>
        </div>
      </section>
    </div>
  );
};

export default HowWeWork;
