import {ReactComponent as retailSVG} from '../assets/images/svgs/cart.svg';
import {ReactComponent as bookingSVG} from '../assets/images/svgs/booking.svg';
import {ReactComponent as chargerSVG} from '../assets/images/svgs/lightning.svg';
import {ReactComponent as stockSVG} from '../assets/images/svgs/stash.svg';
import {ReactComponent as medicalSVG} from '../assets/images/svgs/medical.svg';
import {ReactComponent as casinoSVG} from '../assets/images/svgs/casino.svg';

import file from '../assets/images/svgs/file.svg';
import subtract from '../assets/images/svgs/subtract.svg';

import feedback1 from '../assets/images/feedback_trustly.jpeg';
import feedback2 from '../assets/images/feedback_dose.png';
import feedback3 from '../assets/images/feedback_open.jpeg';
import feedback4 from '../assets/images/feedback_open2.jpeg';
import feedback5 from '../assets/images/feedback_dFM.jpg';
import feedback6 from '../assets/images/feedback_trillinvest.jpg';
import feedback_citygate from '../assets/images/feedback_citygate.jpg';


import bg1 from '../assets/images/bg1.png';
import bg2 from '../assets/images/bg2.png';
import bg3 from '../assets/images/bg3.png';

import dose1 from '../assets/images/domains/dose/dose1.webp';
import dose2 from '../assets/images/domains/dose/dose2.webp';

import openGoMobile1 from '../assets/images/domains/openGo/openGoMobile1.webp';
import openGoMobile2 from '../assets/images/domains/openGo/openGoMobile2.webp';
import openGoTerminal1 from '../assets/images/domains/openGo/openGoTerminal1.webp';
import openGoTerminal2 from '../assets/images/domains/openGo/openGoTerminal2.webp';

import gigslyMobile1 from '../assets/images/domains/gigsly/gigslyMobile1.webp';
import gigslyMobile2 from '../assets/images/domains/gigsly/gigslyMobile2.webp';
import gigslyDesktop1 from '../assets/images/domains/gigsly/gigslyDesktop1.webp';
import gigslyDesktop2 from '../assets/images/domains/gigsly/gigslyDesktop2.webp';

import openOnePOS1 from '../assets/images/domains/openOne/openOnePOS1.webp';
import openOnePOS2 from '../assets/images/domains/openOne/openOnePOS2.webp';
import openOneMobile1 from '../assets/images/domains/openOne/openOneMobile1.webp';
import openOneMobile2 from '../assets/images/domains/openOne/openOneMobile2.webp';
import openOneDesktop1 from '../assets/images/domains/openOne/openOneDesktop1.webp';
import openOneDesktop2 from '../assets/images/domains/openOne/openOneDesktop2.webp';
import openOneDesktop3 from '../assets/images/domains/openOne/openOneDesktop3.webp';
import openOneDesktop4 from '../assets/images/domains/openOne/openOneDesktop4.webp';

import {ReactComponent as PosSVG} from '../assets/images/svgs/pos.svg';
import {ReactComponent as DesktopSVG} from '../assets/images/svgs/desktop.svg';
import {ReactComponent as PhoneSVG} from '../assets/images/svgs/phone.svg';
import {ReactComponent as PosActiveSVG} from '../assets/images/svgs/pos-active.svg';
import {ReactComponent as DesktopActiveSVG} from '../assets/images/svgs/desktopActive.svg';
import {ReactComponent as PhoneActiveSVG} from '../assets/images/svgs/phoneActive.svg';
import {ReactComponent as TerminalSVG} from '../assets/images/svgs/terminal.svg';
import {ReactComponent as TerminalActiveSVG} from '../assets/images/svgs/terminal-active.svg';

import {ReactComponent as MedicalIndustrySVG} from '../assets/images/svgs/industries/medical.svg';
import {ReactComponent as RetailIndustrySVG} from '../assets/images/svgs/industries/retail.svg';
import {ReactComponent as GigslyIndustrySVG} from '../assets/images/svgs/industries/gigsly.svg';

import carousel_OPEN_One from '../assets/images/projects/OPEN_One/carousel.png';
import carousel_OPEN_Go from '../assets/images/projects/OPEN_Go/carousel.png';
import carousel_Gigsly from '../assets/images/projects/Gigsly/carousel.png';
import carousel_Dose from '../assets/images/projects/Dose/carousel.png';

export const bg = {
  bg1,
  bg2,
  bg3,
};

export const projectsCarousels = {
  OPEN_One: carousel_OPEN_One,
  OPEN_Go: carousel_OPEN_Go,
  Gigsly: carousel_Gigsly,
  Dose: carousel_Dose
}

export const svgComponents = {
  retailSVG,
  bookingSVG,
  chargerSVG,
  stockSVG,
  medicalSVG,
  casinoSVG,
};

export const svgs = {
  subtract,
  file,
};

export const feedbacks = {
  feedback1,
  feedback2,
  feedback3,
  feedback4,
  feedback5,
  feedback6,
  feedback_citygate
  }

export const domainPics = [
  {
    domain: 'dose',
    pics: {
      mobile: [
        dose1,
        dose2,
      ],
    },
  },
  {
    domain: 'opengo',
    pics: {
      terminal: [
        openGoTerminal1,
        openGoTerminal2,
      ],
      mobile: [
        openGoMobile1,
        openGoMobile2,
      ],
    },
  },
  {
    domain: 'openone',
    pics: {
      pos: [
        openOnePOS1,
        openOnePOS2,
      ],
      mobile: [
        openOneMobile1,
        openOneMobile2,
      ],
      desktop: [
        openOneDesktop1,
        openOneDesktop2,
        openOneDesktop3,
        openOneDesktop4,
      ],
    },
  },
  {
    domain: 'gigsly',
    pics: {
      mobile: [
        gigslyMobile1,
        gigslyMobile2,
      ],
      desktop: [
        gigslyDesktop1,
        gigslyDesktop2,
      ],
    }
  }
];

export const previewDevices = [
  {
    name: 'mobile',
    svg: {
      active: PhoneActiveSVG,
      inactive: PhoneSVG,
    },
  },
  {
    name: 'pos',
    svg: {
      active: PosActiveSVG,
      inactive: PosSVG,
    },
  },
  {
    name: 'desktop',
    svg: {
      active: DesktopActiveSVG,
      inactive: DesktopSVG,
    },
  },
  {
    name: 'terminal',
    svg: {
      active: TerminalActiveSVG,
      inactive: TerminalSVG,
    },
  },
];

export const industries = [
  {
    title: 'Medical',
    svg: MedicalIndustrySVG,
  },
  {
    title: 'Retail',
    svg: RetailIndustrySVG,
  },
  {
    title: 'Gig economy',
    svg: GigslyIndustrySVG,
  },
];
