import React, { useContext } from 'react';
import {ThemeContext} from './ThemeContext';


const Footer = () => {
  const context = useContext(ThemeContext);
  return (
  <div className="scroll-footer" style={{'color': context.text}}>
    Scroll to discover
  </div>
)}

export default Footer;
