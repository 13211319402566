import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { feedbacks, projectsCarousels } from '../../components/images';
import Carousel from 'react-elastic-carousel';

import './styles.scss';
import {useMediaQuery} from 'react-responsive/src';
import { isMobile } from '../../misc/screen';
import { useEffect } from 'react';

const responsiveDomains = [
  { width: 320, itemsToShow: 1, itemsToScroll: 1 },
  { width: 750, itemsToShow: 2, itemsToScroll: 2 },
  { width: 928, itemsToShow: 3, itemsToScroll: 3 },
  { width: 1316, itemsToShow: 4, itemsToScroll: 4 },
];

const responsiveTestimonials = [
  { width: 320, itemsToShow: 1, itemsToScroll: 1 },
  { width: 700, itemsToShow: 2, itemsToScroll: 1 },
  { width: 978, itemsToShow: 2, itemsToScroll: 2 },
  { width: 1290, itemsToShow: 3, itemsToScroll: 3 },
];

const testimonials = [
  {
    id: 'testimonial_4',
    img: feedbacks.feedback4,
    name: 'Markus Hogsved',
    position: 'Chief technology officer',
    testimonial: '“I have been working with Spark now for well over a year, they are very helpful and solution-oriented. On more than one occasion I have been needing extra developers for projects with short notice, and Spark always managed to deliver.”',
    org: 'OPEN',
  },
  {
    id: 'testimonial_2',
    img: feedbacks.feedback2,
    name: 'Peiman Khorramshahi',
    position: 'CEO',
    testimonial: '“It was easy starting to trust Spark. They always deliver and if there is any trouble, they go over and beyond to help out. Highly recommended!”',
    org: 'Dose Medbox',
  },
  {
    id: 'testimonial_1',
    img: feedbacks.feedback1,
    name: 'Johan Nilsson',
    position: 'Senior Technical Advisor',
    testimonial: '"I\'ve been working for several years with Spark from different companies. The last project Spark built for us was a huge success in bringing online banking payments to the physical world. Spark\'s ingenuity in designing and creating an intuitive app for our customers was exactly what we needed."',
    org: 'Trustly',
  },
  {
    id: 'testimonial_citygate',
    img: feedbacks.feedback_citygate,
    name: 'Magnus Falk',
    position: 'CTO',
    testimonial: '“We have been working with Spark for a couple of years and are very satisfied with both development and product management skill level. They have always been very solution-driven and easy to work with, so I can really recommend them.”',
    org: 'Citygate AB',
  },
  /*{
    id: 'testimonial_3',
    img: feedbacks.feedback3,
    name: 'Christian Saleki',
    position: 'Chief Visionary Officer',
    testimonial: '“Spark has done the full development cycle for us ranging from deep studying of our business idea to a ready for market launch solution. We are really satisfied! Very open-minded and agile company. Very easy to communicate. Thanks for the great service.”',
    org: 'Smart Craft',
  },*/
  {
    id: 'testimonial_5',
    img: feedbacks.feedback5,
    name: 'Parvin Zamani',
    position: 'Business Partner',
    testimonial: '"Looking for a customer experience totally free from frustrations with delivery surprises, delays, new unknown costs, and all sort of excuses? Check out SPARK – an agency that put your interest of a flawless delivery in the center of their universe and that will not stop until you are 100 % happy following upfront agreed costs and timelines."',
    org: 'de Faire Medical',
  },
  {
    id: 'testimonial_6',
    img: feedbacks.feedback6,
    name: 'Dirk Raab',
    position: 'Founding Partner',
    testimonial: '"Spark has helped us out with several inquiries and projects. We are amazed by the highly professional service level and the high quality of their deliverables. They are extremely client-centric, easy to work with, and we always felt that their main object was to always to deliver a final product that we were 100% happy with."',
    org: 'Trillinvest',
  },
];

const items = [
  {
    id: 'openone',
    code: 'OPENOne',
    img: projectsCarousels.OPEN_One,
    text: 'OPEN One',
    description: 'Windows application',
  },
  {
    id: 'opengo',
    code: 'OPENGo',
    img: projectsCarousels.OPEN_Go,
    text: 'OPEN Go',
    description: 'Android application',
  },
  {
    id: 'dose',
    code: 'dose',
    img: projectsCarousels.Dose,
    text: 'Dose',
    description: 'iOS application',
  },
  {
    id: 'gigsly',
    code: 'gigsly',
    img: projectsCarousels.Gigsly,
    text: 'Gigsly',
    description: 'iOS/Android & Web application',
  },
];

const showPaginator = (array, responcive) => {
  let width = window.innerWidth;
  let current = null;
  for (let r of responcive) {
    if (width >= r.width) {
      if (!current || current.width < r.width)
        current = r;
    }
  }

  if (current) {
    if (array.length <= current.itemsToShow)
      return false;
  }

  return true;
};

const WhatWeDo = () => {
  let timer = null;
  const isDesktop = useMediaQuery({ query: '(min-width: 840px)' });
  const [isSwipe, setIsSwipe] = useState(false);
  const [startSwipe, setStartSwipe] = useState(false);
  const history = useHistory();
  const [domainPaginator, setDomainPaginator] = useState(showPaginator(items, responsiveDomains));
  const [testimonialPaginator, setTestimonialPaginator] = useState(showPaginator(testimonials, responsiveTestimonials));
  const [swipeX, setSwipeX] = useState(null);

  const resize = () => {
    setDomainPaginator(showPaginator(items, responsiveDomains));
    setTestimonialPaginator(showPaginator(testimonials, responsiveTestimonials));
  };

  useEffect(() => {
    window.addEventListener('resize', resize);
    return () => {
      window.removeEventListener('resize', resize);
    };
  });

  const moveToDomain = (number, e) => {
    e.stopPropagation();
    e.preventDefault();
    if (!isSwipe) {
      clearTimeout(timer);
      history.push('/ourcases/' + number);
    }
    return false;
  };

  // Don't navigate while swiping
  const stopSwipe = () => {
    if (startSwipe) {
        timer = setTimeout(() => {
            setStartSwipe(false);
            setIsSwipe(false);
            setSwipeX(null);
        }, 10);
    }
  };

  const onCarouselMouseDown = (e) => {
    setStartSwipe(true);
    setSwipeX(e.clientX);
  };

  const onCarouselMouseUp = () => {
    stopSwipe();
  };

  const onCarouselMouseLeave = () => {
    stopSwipe();
  };

  const onCarouselMouseMove = (e) => {
    if (startSwipe && Math.abs(swipeX - e.clientX) > 10) {
        setIsSwipe(true);
    }
  };

  return (
    <div id={isDesktop ? '' : 'ourcases'} className="layout black technologies section">
      <section className="whatwedo">
        <h1 className="hidden">Projects</h1>
        <div className="domains">
          {!isMobile()
            ? <Carousel
                      showArrows={false}
                      pagination={domainPaginator}
                      breakPoints={responsiveDomains}
                      >
                {
                  items && items.map((item) => (
                    <a href={`/ourcases/${item.id}`}>
                      <div className="domains-item" key={item.id} onClick={(event)=>moveToDomain(item.id, event)} onMouseDown={onCarouselMouseDown}
                         onMouseUp={onCarouselMouseUp} onMouseLeave={onCarouselMouseLeave} onMouseMove={onCarouselMouseMove}>
                        <div className="domains-item--img" style={typeof item.textColor == 'string' ? {color: item.textColor} : null}>
                          <img src={item.img} alt={item.code} draggable="false" />
                        </div>
                        <div className="domains-item-textContainer">
                          <div className="domains-item-textContainer--text"
                              style={typeof item.textColor == 'string' ? {color: item.textColor} : null}>{item.text}</div>
                          <div className="domains-item-textContainer--description">{item.description}</div>
                        </div>
                      </div>
                    </a>
                  ))
                }
              </Carousel>
            : <div className='mobile'>
                {items && items.map((item) => (
                  <a href={`/ourcases/${item.id}`}>
                    <div className="domains-item" key={item.id} onClick={(event)=>moveToDomain(item.id, event)}>
                      <div className="domains-item--img" style={typeof item.textColor == 'string' ? {color: item.textColor} : null}>
                        <img src={item.img} />
                      </div>
                      <div className="domains-item-textContainer">
                        <div className="domains-item-textContainer--text"
                            style={typeof item.textColor == 'string' ? {color: item.textColor} : null}>{item.text}</div>
                        <div className="domains-item-textContainer--description">{item.description}</div>
                      </div>
                    </div>
                  </a>
                ))}
              </div>
          }
        </div>
        <div className="feedbacks">
          <Carousel showArrows={false}
                    pagination={testimonialPaginator}
                    breakPoints={responsiveTestimonials}>
            {
              testimonials && testimonials.map((item)=> (
                <div className="feedbacks-item" key={item.id}>
                  <div className="feedbacks-item-container">
                    <div className="feedbacks-item-container--img">
                      <img src={item.img} alt={item.code} draggable="false" />
                    </div>
                    <div className="feedbacks-item-container-person">
                      <div className="feedbacks-item-container-person--name">
                        {item.name}
                      </div>
                      <div className="feedbacks-item-container-person--position">
                        {item.position}, {item.org}
                      </div>
                    </div>
                  </div>
                  <div className="feedbacks-item--feedback">
                    {item.testimonial}
                  </div>
                </div>
              ))
            }
          </Carousel>
        </div>
      </section>
    </div>
  );
};

export default React.memo(WhatWeDo);
