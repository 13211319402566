import React, {useEffect, useState} from 'react';
import {Button, Col, Form, Input, Row, Upload, message} from 'antd';
import {PlusOutlined} from '@ant-design/icons';
import UploadList from './UploadList';
import PropTypes from 'prop-types';
import './styles.scss';
import axios from 'axios';

const { TextArea } = Input;

const allowedFileExtensions = ["doc", "docx", "xls", "xlsx", "pdf", "odt", "rtf"];
const allowedFileSize = 10 * 1024 * 1024; // 10Mb;

const MailForm = (props) => {

  const [form] = Form.useForm();
  const formRef = React.createRef();
  const [fileList, setFileList] = useState([]);
  const uploadButtonRef = React.createRef();

  let textAreas = document.getElementsByClassName('form-textarea');
  if (textAreas.length) {
    for (let i = 0; i < textAreas.length; i ++) {
      textAreas[i].children[0].style.minWidth = `${textAreas[i].children[0].clientWidth}px`;
      textAreas[i].children[0].style.maxWidth = `${textAreas[i].children[0].clientWidth}px`;
    }
  }

  useEffect(()=> {
  }, []);

  const onFinish = (values) => {

    axios.post(`${process.env.REACT_APP_BACKEND_HOST}/api/mail/send`, {
      to: process.env.REACT_APP_EMAIL_RECIPIENT,
      subject: `SPARK site: ${props.name}`,
      body: `<b>Site form:</b> ${props.name}<br/>` +
            `<b>From:</b> ${values[`${props.name}_name`]} (${values[`${props.name}_email`]})<br/>` +
            `<b>Text:</b><br/>${values[`${props.name}_about`].replace(/\n/g, '<br/>')}`,
      files: fileList,
    }).then(r => {
      message.success('Your message successfully sent');
      form.resetFields();
      setFileList([]);
    });
  };

  const onUpload = (info) => {
    const fn = info.file.originFileObj.name;
    const ext = fn.split(".")[fn.split(".").length - 1].trim().toLowerCase();
    if (allowedFileExtensions.indexOf(ext) == -1) {
      message.error('Wrong file extension');
      removeFile(info.file);
      return;
    }
    if (info.file.originFileObj.size > allowedFileSize) {
      message.error('Wrong file size');
      removeFile(info.file);
      return;
    }

    if (info.fileList.length > 5) {
      return;
    }
    if (info.file.status !== 'uploading') {

    }
    if (info.file.status === 'done') {
      

      let fList = info.fileList;
      let index = fList.findIndex(f => f.uid === info.file.uid);
      if (index !== -1) {
        fList[index].uploadedName = info.file.response.uploadedName;
        setFileList(fList);
      }
    } else if (info.file.status === 'error') {
      message.error('Something wrong');
      removeFile(info.file);
      return;
    }
    else {
      setFileList(info.fileList);
    }
  };

  const emailValidator = async (rule, value)  => {
    if (!value || !value.trim().length)
      return Promise.reject('Can’t be empty');
    if (!/.+@.+\..+/.test(value))
      return Promise.reject('That’s not email');

    return Promise.resolve();
  };

  const removeFile = (file) => {
    setFileList(fileList.filter(f => f.uid !== file.uid));
  };

  const preventRequest = () => false;

  return(
    <div>
      <Form
        form={form}
        ref={formRef}
        layout="vertical"
        className="form"
        onFinish={onFinish}
      >
        <Row className="form-row">
          <Col span={11}>
            <Form.Item
              name={`${props.name}_name`}
              required={true}
              rules={[{ required: true, message: 'Can’t be empty' }]} >
              <Input placeholder="Name" size="large" className="form-input" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name={`${props.name}_email`}
              required={true}
              rules={[{validator: async (rule, value) => await emailValidator(rule, value)}]} >
              <Input placeholder="Email" size="large" className="form-input" />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          name={`${props.name}_about`}
          required={true}
          rules={[{ required: true, message: 'Can’t be empty' }]} >
          <TextArea
            placeholder={props.descriptionFieldTitle ? props.descriptionFieldTitle : 'About me'}
            autoSize={{ minRows: 1, maxRows: 6 }}
            className="form-textarea"
            bordered={false}
            showCount maxLength={250}
          />
        </Form.Item>
        <Form.Item
          required={true}
          className="form-uploader">
          <Upload
            action={`${process.env.REACT_APP_BACKEND_HOST}/api/file/upload`}
            name="attach"
            onChange={onUpload}
            fileList={fileList}
            itemRender={(originNode, file, currFileList) => {
              return (
                <UploadList originNode={originNode} file={file} currFileList={fileList} removeFile={removeFile} />
              );
            }}
            accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,text/plain, application/pdf">
            <Button ref={uploadButtonRef} className="hide"/>
          </Upload>
          <Button icon={<PlusOutlined />} className="form-upload-btn" onClick={() => {uploadButtonRef.current.click();}}>
              {props.attachmentButton ? props.attachmentButton : 'Add resume'}
              <div className="upload-label">Word, PDF, less 10 mb</div>
            </Button>
        </Form.Item>
        <Form.Item className="form-submit-wrapper">
          <Button className="form-submit-btn" htmlType="submit">{props.buttonText}</Button>
        </Form.Item>
      </Form>
    </div>
  );
};

MailForm.propTypes = {
  name: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  attachmentButton: PropTypes.string,
};

export default MailForm;
