import React from 'react';
import {svgs} from "../images";

const UploadList = (props) => {

    return (
        <div className="uploadList">
            <div className="uploadList__filePic">
                <img src={svgs.file} alt="file icon"/>
            </div>
            <div className="uploadList__fileName">
                {props.file.name}
            </div>
            <div className="uploadList__deleteButton">
                <img src={svgs.subtract} alt="delete icon" onClick={() => {props.removeFile(props.file)}}/>
            </div>
        </div>
    );
};

export default UploadList;
