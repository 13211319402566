import React, {useState, useEffect} from 'react';
import {useMediaQuery } from 'react-responsive';
import { useHistory } from 'react-router-dom';
import ContactUsBlock from '../../components/ContactUsBlock/ContactUsBlock';
import schepaImg from '../../assets/images/schepa.jpg';
import './styles.scss';

const ContactsDetailsPage = () => {
  const history = useHistory();
  const isDesktop = useMediaQuery({ query: '(min-width: 840px)' });
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    if (!isDesktop)
      window.scrollTo(0, 0);
  }, []);

  const toggleHover = () => {
    setIsHovered(prevState => !prevState);
  };

  const onBackClick = () => {
    if (isDesktop)
      history.push('/#contacts');
    else 
      history.goBack();
  };

  return (
    <section className="content static">
      <div className="horizontal-slide contact-us" style={isDesktop ? {height: "100vh"} : {}}>
        <div className="back" onClick={onBackClick} onMouseEnter={toggleHover} onMouseLeave={toggleHover}>
          <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.0312 7.01562C15.5749 7.01562 16.0156 7.45634 16.0156 8C16.0156 8.54366 15.5749 8.98438 15.0312 8.98438H3.82812L8.70754 13.9048C9.0939 14.2944 9.09258 14.923 8.7046 15.311C8.31546 15.7002 7.68454 15.7002 7.2954 15.311L0.691481 8.70711C0.300957 8.31658 0.300958 7.68342 0.691482 7.29289L7.2954 0.688971C7.68454 0.299833 8.31546 0.299833 8.7046 0.688971C9.09258 1.07696 9.0939 1.7056 8.70754 2.09521L3.82812 7.01562H15.0312Z" fill={!isHovered ? '#fff' : '#F26D6B'}/>
          </svg>
        </div>
        <div className="horizontal-slide__left-side">
          <div className="content contact-us__content">
            <h1 className="contact-us__title">Why сhoose Us</h1>
            <div className="contact-us__person">
              <div className="contact-us__pic">
                <img src={schepaImg} alt="ceo" />
              </div>
              <div className="contact-us__person--details">
                <h3>Artem Oshchepkov</h3>
                <div className="contact-us__position">
                  Co-Founder & CEO
                </div>
              </div>
            </div>

            <div className="contact-us__text">
              Simply because we will do everything, and I mean everything to make sure that your project succeeds. Quality is in our DNA which is why we have handpicked every team member out of hundreds of applicants to make sure the ideologies we have are followed. We understand that our success comes from yours. That is why we push ourselves to become better every day and most important of all, to always deliver quality. We are in it for the win!
              <div className="contact-us__footer">
                Spark, 2020
              </div>
            </div>
          </div>
        </div>
        <div className="horizontal-slide__right-side">
          <ContactUsBlock title="Contact Us?" formName="details_form" buttonText="Send message" descriptionFieldTitle="Tell us your problem" attachmentButton="Add document"/>
        </div>
      </div>
    </section>
  );
};

export default ContactsDetailsPage;
