import React, {useRef, useEffect, useState} from 'react';
import {Collapse, Row, Tabs, Timeline} from 'antd';
import { useHistory } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import ContactUsBlock from '../../components/ContactUsBlock/ContactUsBlock';
import 'antd/es/timeline/style/index.css';
import 'antd/es/tabs/style/index.css';
import './styles.scss';

const { TabPane } = Tabs;
const { Panel } = Collapse;

const TimeAndMaterialsPage = (props) => {
  const isDesktop = useMediaQuery({ query: '(min-width: 840px)' });
  const [isHovered, setIsHovered] = useState(false);
  const [activePanel, setActivePanel] = useState("0");

  const toggleHover = () => {
    setIsHovered(prevState => !prevState);
  };

  const history = useHistory();

  const myRef = useRef(null);

  useEffect(() => {
    if (!isDesktop)
      setTimeout(() => {handleCollapseClick('1')}, 50);
      window.scrollTo(0, 0);
  }, []);

  const onBackClick = () => {
    if (isDesktop)
      history.push('/#howwework');
    else 
      history.goBack();
  };

  const handleCollapseClick = (activeKey) => {
    if (typeof activeKey === 'undefined') {
      return;
    }
    setActivePanel(activeKey);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });

    // fix iOS 15: safari bug
    setTimeout(() => {
      let height = document.querySelectorAll(`.horizontal-slide__left-side`)[0].clientHeight;
      document.querySelectorAll(`.horizontal-slide__left-side`)[0].style.height = `${height}px`;
    }, 100);
  };

  return (
    <section className="content static full-height">
      <div className="horizontal-slide time-and-materials" ref={myRef}>
        <div className="back" onClick={onBackClick} onMouseEnter={toggleHover} onMouseLeave={toggleHover}>
          <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.0312 7.01562C15.5749 7.01562 16.0156 7.45634 16.0156 8C16.0156 8.54366 15.5749 8.98438 15.0312 8.98438H3.82812L8.70754 13.9048C9.0939 14.2944 9.09258 14.923 8.7046 15.311C8.31546 15.7002 7.68454 15.7002 7.2954 15.311L0.691481 8.70711C0.300957 8.31658 0.300958 7.68342 0.691482 7.29289L7.2954 0.688971C7.68454 0.299833 8.31546 0.299833 8.7046 0.688971C9.09258 1.07696 9.0939 1.7056 8.70754 2.09521L3.82812 7.01562H15.0312Z" fill={!isHovered ? '#fff' : '#F26D6B'}/>
          </svg>
        </div>
        <div className="horizontal-slide__left-side time-and-materials-info">
          <div className="time-and-materials-info-content">
            <h2 className="time-and-materials-info__title">Dedicated Team</h2>
            <div className="time-and-materials-info__list">
              { isDesktop && <Tabs defaultActiveKey="1" className="time-and-materials-tabs">
                <TabPane tab="PROCESS" key="1">
                  <Timeline>
                    <Timeline.Item color="#F26D6B">
                      <div className="timeline-item__title">Preparation <span className="timeline-item__title-label">Up to 2 weeks</span></div>
                      <div className="timeline-item__text">
                        <p>
                          We organize workshops to talk to stakeholders in order to understand your business and your project requirements.*
                        </p>
                        <p>
                          We then define needed expertise and team size.
                        </p>
                      </div>

                      <div className="timeline-item__footer">
                        <svg width="17" height="11" viewBox="0 0 17 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M1 2C1 1.44772 1.44772 1 2 1H11.4172C11.4702 1 11.5211 1.02107 11.5586 1.05858L15.2929 4.79289C15.6834 5.18342 15.6834 5.81658 15.2929 6.20711L11.5586 9.94142C11.5211 9.97893 11.4702 10 11.4172 10H2C1.44772 10 1 9.55228 1 9V2Z" stroke="#F26D6B" stroke-width="2"/>
                        </svg>
                        <span className="timeline-item__footer--text">Workshops can be done both ways on-site or remotely.*</span>
                      </div>
                    </Timeline.Item>
                    <Timeline.Item color="#F26D6B">
                      <div className="timeline-item__title">Spark gets GO <span className="timeline-item__title-label">Up to 12 weeks</span></div>
                      <div className="timeline-item__text">
                        <ol className="timeline-item__list">
                          <li>We find candidates possessing needed expertise</li>
                          <li>We interview and test them internally*</li>
                          <li>We assemble a team of suitable experts</li>
                          <li>We have a kick-off to introduce the team**</li>
                          <li>We start sparking.</li>
                        </ol>
                      </div>
                      <div className="timeline-item__footer">
                        <svg width="17" height="11" viewBox="0 0 17 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M1 2C1 1.44772 1.44772 1 2 1H11.4172C11.4702 1 11.5211 1.02107 11.5586 1.05858L15.2929 4.79289C15.6834 5.18342 15.6834 5.81658 15.2929 6.20711L11.5586 9.94142C11.5211 9.97893 11.4702 10 11.4172 10H2C1.44772 10 1 9.55228 1 9V2Z" stroke="#F26D6B" stroke-width="2"/>
                        </svg>
                        <span className="timeline-item__footer--text">Candidates can also be verified by you.*</span>
                      </div>
                      <div className="timeline-item__footer">
                        <svg width="17" height="11" viewBox="0 0 17 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M1 2C1 1.44772 1.44772 1 2 1H11.4172C11.4702 1 11.5211 1.02107 11.5586 1.05858L15.2929 4.79289C15.6834 5.18342 15.6834 5.81658 15.2929 6.20711L11.5586 9.94142C11.5211 9.97893 11.4702 10 11.4172 10H2C1.44772 10 1 9.55228 1 9V2Z" stroke="#F26D6B" stroke-width="2"/>
                        </svg>
                        <span className="timeline-item__footer--text">Workshops can be done both on-site or remotely.**</span>
                      </div>
                    </Timeline.Item>
                    <Timeline.Item color="#F26D6B">
                      <div className="timeline-item__title">We spark you up <span className="timeline-item__title-label">Continuously</span></div>
                      <div className="timeline-item__text">
                        <ol className="timeline-item__list">
                          <li>We provide you with dedicated and devoted experts scaling the team on your demand</li>
                          <li>We supervise the delivery</li>
                          <li>We organize regular feedback</li>
                          <li>We take care of infrastructure for work and administrative costs</li>
                          <li>We improve or implement missing workflows if needed</li>
                          <li>We organize on-site travels.</li>
                        </ol>
                      </div>
                    </Timeline.Item>
                  </Timeline>
                </TabPane>
                <TabPane tab="DELIVERY" key="2">
                  <div className="materials">
                    <div className="materials-item">
                      <div className="materials-item-title">
                        <div className="materials-item-title--img">
                          <svg width="42" height="38" viewBox="0 0 42 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="14" y="29" width="14" height="8" rx="2" stroke="#F26D6B" stroke-width="2"/>
                            <rect x="17" y="1" width="8" height="8" rx="4" stroke="#F26D6B" stroke-width="2"/>
                            <rect x="1" y="1" width="8" height="8" rx="4" stroke="#F26D6B" stroke-width="2"/>
                            <rect x="33" y="1" width="8" height="8" rx="4" stroke="#F26D6B" stroke-width="2"/>
                            <path d="M21 9V29" stroke="#F26D6B" stroke-width="2"/>
                            <path d="M21 19H9C6.79086 19 5 17.2091 5 15V9" stroke="#F26D6B" stroke-width="2"/>
                            <path d="M21 19H33C35.2091 19 37 17.2091 37 15V9" stroke="#F26D6B" stroke-width="2"/>
                          </svg>
                        </div>
                        <div className="materials-item-title--text">Dedication</div>
                      </div>
                      <div className="materials-item-text">
                        The team will be dedicated to your company.
                        The experts will be embedded into your development process.
                        They will report and communicate to you directly.
                        They will also comply with your workflows, management style, and technical platforms.
                      </div>
                    </div>
                    <div className="materials-item">
                      <div className="materials-item-title">
                        <div className="materials-item-title--img">
                          <svg width="28" height="30" viewBox="0 0 28 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M26.1418 14.5046L25.7466 5.142C25.7466 4.99685 25.718 4.85313 25.6625 4.71903C25.6069 4.58493 25.5255 4.46309 25.4229 4.36046L22.3861 1.32371C22.2835 1.22108 22.1616 1.13967 22.0275 1.08413C21.8934 1.02858 21.7497 0.999996 21.6046 1H5.57303C5.42789 0.999998 5.28416 1.02858 5.15007 1.08413C5.01597 1.13967 4.89413 1.22108 4.79149 1.32371L1.75475 4.36046C1.65212 4.46309 1.5707 4.58493 1.51516 4.71903C1.45961 4.85313 1.43102 4.99685 1.43102 5.142L1.03579 14.5046C0.244064 21.6302 12.8427 29 13.5888 29C14.3349 29 26.9336 21.6302 26.1418 14.5046Z" stroke="#F26D6B" stroke-width="2"/>
                            <path d="M25.7466 5.142L26.1418 14.5046C26.9336 21.6302 14.3349 29 13.5888 29V1H21.6046C21.7497 0.999996 21.8935 1.02858 22.0275 1.08413C22.1616 1.13967 22.2835 1.22108 22.3861 1.32371L25.4229 4.36046C25.5255 4.46309 25.6069 4.58493 25.6625 4.71903C25.718 4.85313 25.7466 4.99685 25.7466 5.142Z" fill="#F26D6B"/>
                          </svg>
                        </div>
                        <div className="materials-item-title--text">Administration</div>
                      </div>
                      <div className="materials-item-text">
                        We take care of recruitment, infrastructure, administrative costs, vacation, and sick leaves that save a lot of your money.
                      </div>
                    </div>
                    <div className="materials-item">
                      <div className="materials-item-title">
                        <div className="materials-item-title--img">
                          <svg width="28" height="26" viewBox="0 0 28 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14 1L17.8206 8.74139L26.3637 9.98278L20.1819 16.0086L21.6412 24.5172L14 20.5L6.35879 24.5172L7.81813 16.0086L1.63627 9.98278L10.1794 8.74139L14 1Z" stroke="#F26D6B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                          </svg>
                        </div>
                        <div className="materials-item-title--text">experts</div>
                      </div>
                      <div className="materials-item-text">
                        <div className="materials-item-text">
                          We call ourselves experts. And we:
                        </div>
                        <ul className="list">
                          <li className="list-item">always act proactively</li>
                          <li className="list-item">always look for business problems and learn about our clients' business to come up with proper solutions</li>
                          <li className="list-item">have both hard and soft skills as well as experience, knowledge, and passion to make our clients' business better.</li>
                        </ul>
                      </div>
                    </div>
                    <div className="materials-item">
                      <div className="materials-item-title">
                        <div className="materials-item-title--img">
                          <svg width="35" height="32" viewBox="0 0 35 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M23.2543 17.6109C23.6728 17.6109 24.0493 17.2343 24.0493 16.8159V14.8912H27.8569C29.4887 14.8912 30.8276 13.5523 30.8276 11.9205V9.5774C32.8359 9.20084 34.3841 7.44351 34.3841 5.30962C34.3841 2.92469 32.4594 1 30.0744 1C27.6895 1 25.7648 2.92469 25.7648 5.30962C25.7648 7.44351 27.2711 9.20084 29.2794 9.5774V11.9205C29.2794 12.6736 28.6518 13.3013 27.8987 13.3013H24.0912V11C24.0912 10.5816 23.7146 10.205 23.2962 10.205H11.246C10.8276 10.205 10.451 10.5816 10.451 11V13.2594H6.64347C5.01167 13.2594 3.67275 14.5983 3.67275 16.2301V18.4059C1.66439 18.7824 0.199951 20.5397 0.199951 22.6736C0.199951 25.0586 2.12464 26.9833 4.50957 26.9833C6.89451 26.9833 8.8192 25.0586 8.8192 22.6736C8.8192 20.5397 7.27108 18.7824 5.22087 18.4059V16.272C5.22087 15.5188 5.84849 14.8912 6.60162 14.8912H10.4092V16.8577C10.4092 17.2761 10.7857 17.6527 11.2041 17.6527H16.4343V24.9331C16.4343 26.5649 17.7732 27.9038 19.405 27.9038H20.4928C20.7857 29.6611 22.3338 31 24.133 31H28.8192C30.8694 31 32.543 29.3264 32.543 27.2761C32.543 25.2259 30.8694 23.5523 28.8192 23.5523H24.2167C22.5012 23.5523 21.0368 24.7238 20.6184 26.3138H19.4468C18.6937 26.3138 18.0661 25.6862 18.0661 24.9331V17.6109H23.2543ZM27.3129 5.30962C27.3129 3.80335 28.5263 2.54812 30.0744 2.54812C31.5807 2.54812 32.8359 3.76151 32.8359 5.30962C32.8359 6.85774 31.6225 8.07113 30.0744 8.07113C28.5263 8.07113 27.3129 6.8159 27.3129 5.30962ZM7.22924 22.6736C7.22924 24.1799 6.01585 25.4351 4.46773 25.4351C2.96146 25.4351 1.70623 24.2218 1.70623 22.6736C1.70623 21.1674 2.91962 19.9121 4.46773 19.9121C6.01585 19.9121 7.22924 21.1674 7.22924 22.6736ZM24.2167 25.1004H28.9029C30.0744 25.1004 31.0368 26.0628 31.0368 27.2343C31.0368 28.4059 30.0744 29.3682 28.9029 29.3682H24.2167C23.0451 29.3682 22.0828 28.4059 22.0828 27.2343C22.0828 26.0628 23.0451 25.1004 24.2167 25.1004ZM12.041 11.7531H22.4594V16.0209H12.041V11.7531Z" fill="#F26D6B" stroke="#F26D6B" stroke-width="0.4"/>
                          </svg>
                        </div>
                        <div className="materials-item-title--text">Workflows</div>
                      </div>
                      <div className="materials-item-text">
                        <div className="materials-item-text">
                          Our experts can help your team setting up workflows:
                        </div>
                        <ul className="list">
                          <li className="list-item">We can set up an Agile development process</li>
                          <li className="list-item">We can set up CI/CD</li>
                          <li className="list-item">We can teach how to write automated tests</li>
                          <li className="list-item">We can help with git-flow and code review etc.</li>
                        </ul>
                      </div>
                    </div>
                    <div className="materials-item">
                      <div className="materials-item-title">
                        <div className="materials-item-title--img">
                          <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.47541 14.6955C8.41176 14.6955 9.17082 13.9365 9.17082 13.0001C9.17082 12.0637 8.41176 11.3047 7.47541 11.3047C6.53906 11.3047 5.78 12.0637 5.78 13.0001C5.78 13.9365 6.53906 14.6955 7.47541 14.6955Z" fill="#F26D6B"/>
                            <path d="M12.9999 11.3044C12.6645 11.3044 12.3367 11.4039 12.0579 11.5901C11.7791 11.7764 11.5618 12.0412 11.4334 12.351C11.3051 12.6608 11.2715 13.0017 11.3369 13.3306C11.4023 13.6595 11.5638 13.9616 11.8009 14.1987C12.038 14.4358 12.3401 14.5973 12.669 14.6627C12.9979 14.7282 13.3388 14.6946 13.6486 14.5663C13.9584 14.438 14.2232 14.2206 14.4095 13.9418C14.5958 13.663 14.6952 13.3352 14.6952 12.9999C14.6952 12.5502 14.5166 12.119 14.1987 11.801C13.8807 11.4831 13.4495 11.3045 12.9999 11.3044Z" fill="#F26D6B"/>
                            <path d="M18.5244 14.6955C19.4607 14.6955 20.2198 13.9365 20.2198 13.0001C20.2198 12.0637 19.4607 11.3047 18.5244 11.3047C17.588 11.3047 16.8289 12.0637 16.8289 13.0001C16.8289 13.9365 17.588 14.6955 18.5244 14.6955Z" fill="#F26D6B"/>
                            <path d="M13.0011 0C9.55445 0.00358598 6.25 1.37418 3.81267 3.81109C1.37534 6.248 0.00417948 9.55222 2.17912e-06 12.9988C-0.00117164 15.0158 0.471897 17.0048 1.38102 18.8053L1.50553 19.0539L0.39438 25.9415L0.534592 25.9423C0.639635 25.9429 11.0498 26 13.0011 26C16.4489 26 19.7555 24.6304 22.1934 22.1924C24.6314 19.7544 26.001 16.4478 26.001 13C26.001 9.55219 24.6314 6.24559 22.1934 3.80762C19.7555 1.36965 16.4489 1.04071e-05 13.0011 1.04071e-05V0ZM12.9987 23.7191C11.6801 23.7191 6.38552 23.6915 3.22207 23.675L3.07248 23.6742L3.86874 18.7298L3.87507 18.6916L3.42067 17.783C2.67077 16.2998 2.28033 14.6609 2.28091 12.9988C2.28115 10.8788 2.91004 8.80642 4.08807 7.0438C5.26609 5.28118 6.94034 3.90747 8.8991 3.09639C10.8578 2.28531 13.0131 2.07328 15.0924 2.48711C17.1716 2.90095 19.0815 3.92206 20.5804 5.42133C22.0793 6.9206 23.1 8.83068 23.5134 10.91C23.9267 12.9894 23.7142 15.1446 22.9027 17.1032C22.0911 19.0617 20.717 20.7357 18.9541 21.9133C17.1913 23.0909 15.1187 23.7193 12.9987 23.7191Z" fill="#F26D6B"/>
                          </svg>
                        </div>
                        <div className="materials-item-title--text">Regular feedback</div>
                      </div>
                      <div className="materials-item-text">
                        We organize regular feedback meetings with our clients to find out if everything is up to standards and ensure that the delivery continues in a productive and expeditious way.
                      </div>
                    </div>
                    <div className="materials-item">
                      <div className="materials-item-title">
                        <div className="materials-item-title--img">
                          <svg width="26" height="33" viewBox="0 0 26 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="1" y="2" width="24" height="30" rx="2" stroke="#F26D6B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            <rect x="6" width="14" height="4" rx="1" fill="#F26D6B"/>
                            <path d="M7 11H19" stroke="#F26D6B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M7 17H19" stroke="#F26D6B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M7 23H19" stroke="#F26D6B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                          </svg>
                        </div>
                        <div className="materials-item-title--text">Supervising</div>
                      </div>
                      <div className="materials-item-text">
                        Internal status reports with our experts ensure that the delivery is supervised and that we can react proactively before small problems grow into a bigger problem.
                      </div>
                    </div>
                    <div className="materials-item">
                      <div className="materials-item-title">
                        <div className="materials-item-title--img">
                          <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="13" cy="13" r="12" stroke="#F26D6B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="4 4"/>
                            <path d="M13 5V13L17 17" stroke="#F26D6B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                          </svg>
                        </div>
                        <div className="materials-item-title--text">Flexible working hours</div>
                      </div>
                      <div className="materials-item-text">
                        We always tend to find a way to work in a client's timezone so communication between remote teams loses no quality.
                      </div>
                    </div>
                    <div className="materials-item">
                      <div className="materials-item-title">
                        <div className="materials-item-title--img">
                          <svg width="32" height="28" viewBox="0 0 32 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14.104 15.2697L10.7344 26.0044C10.6282 26.3428 10.3512 26.6098 9.99808 26.6424C8.30033 26.7992 7.03666 25.8016 7.17361 24.0212L7.83124 10.7169L2.38967 6.23807C1.64886 5.62833 1.01153 4.82094 1.00026 3.86154C0.98408 2.48488 1.72795 1.52047 3.06413 1.1584C4.03387 0.895632 5.02663 1.32257 5.83607 1.91775L11.4735 6.06291C13.9017 5.30411 23.1085 2.06656 24.9296 2.01597C26.3903 1.97171 27.1925 2.93441 27.4042 4.22633C27.4617 4.5776 27.2734 4.91446 26.9717 5.10339L17.4427 11.071C19.8348 13.2717 22.2722 15.4725 24.6692 17.6732C24.9254 17.9084 25.2853 17.994 25.6198 17.8989L29.4342 16.8149C29.719 16.7339 30.0254 16.793 30.2597 16.9741V16.9741C30.6682 17.2897 30.7427 17.8772 30.4259 18.2848L24.3371 26.1193C24.0215 26.5255 23.4367 26.5996 23.0297 26.2851V26.2851C22.7932 26.1023 22.6585 25.8173 22.6676 25.5184L22.7879 21.5486C22.7985 21.1993 22.6259 20.8699 22.333 20.6793C19.6067 18.9046 16.878 17.0871 14.104 15.2697Z" stroke="#F26D6B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                          </svg>
                        </div>
                        <div className="materials-item-title--text">On-site travels</div>
                      </div>
                      <div className="materials-item-text">
                        The best way to get to know a client’s business is to have an on-site visit. It gives an incredible immersion and experience which helps a lot to understand all your problems and needs.
                      </div>
                    </div>
                  </div>
                </TabPane>
                <TabPane tab="USE" key="3">
                  <div className="materials">
                    <div className="materials-item">
                      <div className="materials-item-text">
                        <ul className="list">
                          <li className="list-item">You want to add more workforce to your existing in-house R&D team to boost up your development but you can not find in-house experts</li>
                          <li className="list-item">You need direct and straightforward communication with each team member</li>
                          <li className="list-item">You need fast scalability and flexibility for your team</li>
                          <li className="list-item">You have long-term IT projects with original goals and rapidly changing scopes</li>
                          <li className="list-item">You need full control over the development process</li>
                          <li className="list-item">You want to access cross-border high-quality expertise and knowledge sharing to support the exchange of ideas and diversify the skills of your in-house R&D team</li>
                          <li className="list-item">You want to reduce development costs as much as possible.</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </TabPane>
              </Tabs>}
              { !isDesktop && <Collapse accordion activeKey={activePanel} ghost onChange={handleCollapseClick}>
                <Panel header="PROCESS" className="custom-collapse-panel" key="1" showArrow={false}>
                  <Timeline>
                    <Timeline.Item color="#F26D6B">
                      <div className="timeline-item__title">Preparation <span className="timeline-item__title-label">Up to 2 weeks</span></div>
                      <div className="timeline-item__text">
                        <p>
                          We organize workshops to talk to stakeholders in order to understand your business and your project requirements.*
                        </p>
                        <p>
                          We then define needed expertise and team size.
                        </p>
                      </div>

                      <div className="timeline-item__footer">
                        <svg width="17" height="11" viewBox="0 0 17 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M1 2C1 1.44772 1.44772 1 2 1H11.4172C11.4702 1 11.5211 1.02107 11.5586 1.05858L15.2929 4.79289C15.6834 5.18342 15.6834 5.81658 15.2929 6.20711L11.5586 9.94142C11.5211 9.97893 11.4702 10 11.4172 10H2C1.44772 10 1 9.55228 1 9V2Z" stroke="#F26D6B" stroke-width="2"/>
                        </svg>
                        <span className="timeline-item__footer--text">Workshops can be done both ways on-site or remotely.*</span>
                      </div>
                    </Timeline.Item>
                    <Timeline.Item color="#F26D6B">
                      <div className="timeline-item__title">Spark gets GO <span className="timeline-item__title-label">Up to 12 weeks</span></div>
                      <div className="timeline-item__text">
                        <ol className="timeline-item__list">
                          <li>We find candidates possessing needed expertise</li>
                          <li>We interview and test them internally*</li>
                          <li>We assemble a team of suitable experts</li>
                          <li>We have a kick-off to introduce the team**</li>
                          <li>We start sparking.</li>
                        </ol>
                      </div>
                      <div className="timeline-item__footer">
                        <svg width="17" height="11" viewBox="0 0 17 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M1 2C1 1.44772 1.44772 1 2 1H11.4172C11.4702 1 11.5211 1.02107 11.5586 1.05858L15.2929 4.79289C15.6834 5.18342 15.6834 5.81658 15.2929 6.20711L11.5586 9.94142C11.5211 9.97893 11.4702 10 11.4172 10H2C1.44772 10 1 9.55228 1 9V2Z" stroke="#F26D6B" stroke-width="2"/>
                        </svg>
                        <span className="timeline-item__footer--text">Candidates can also be verified by you.*</span>
                      </div>
                      <div className="timeline-item__footer">
                        <svg width="17" height="11" viewBox="0 0 17 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M1 2C1 1.44772 1.44772 1 2 1H11.4172C11.4702 1 11.5211 1.02107 11.5586 1.05858L15.2929 4.79289C15.6834 5.18342 15.6834 5.81658 15.2929 6.20711L11.5586 9.94142C11.5211 9.97893 11.4702 10 11.4172 10H2C1.44772 10 1 9.55228 1 9V2Z" stroke="#F26D6B" stroke-width="2"/>
                        </svg>
                        <span className="timeline-item__footer--text">Workshops can be done both on-site or remotely.**</span>
                      </div>
                    </Timeline.Item>
                    <Timeline.Item color="#F26D6B">
                      <div className="timeline-item__title">We spark you up <span className="timeline-item__title-label">Continuously</span></div>
                      <div className="timeline-item__text">
                        <ol className="timeline-item__list">
                          <li>We provide you with dedicated and devoted experts scaling the team on your demand</li>
                          <li>We supervise the delivery</li>
                          <li>We organize regular feedback</li>
                          <li>We take care of infrastructure for work and administrative costs</li>
                          <li>We improve or implement missing workflows if needed</li>
                          <li>We organize on-site travels.</li>
                        </ol>
                      </div>
                    </Timeline.Item>
                  </Timeline>
                </Panel>
                <Panel header="DELIVERY" className="custom-collapse-panel" key="2" showArrow={false}>
                  <div className="materials">
                    <div className="materials-item">
                      <div className="materials-item-title">
                        <div className="materials-item-title--img">
                          <svg width="42" height="38" viewBox="0 0 42 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="14" y="29" width="14" height="8" rx="2" stroke="#F26D6B" stroke-width="2"/>
                            <rect x="17" y="1" width="8" height="8" rx="4" stroke="#F26D6B" stroke-width="2"/>
                            <rect x="1" y="1" width="8" height="8" rx="4" stroke="#F26D6B" stroke-width="2"/>
                            <rect x="33" y="1" width="8" height="8" rx="4" stroke="#F26D6B" stroke-width="2"/>
                            <path d="M21 9V29" stroke="#F26D6B" stroke-width="2"/>
                            <path d="M21 19H9C6.79086 19 5 17.2091 5 15V9" stroke="#F26D6B" stroke-width="2"/>
                            <path d="M21 19H33C35.2091 19 37 17.2091 37 15V9" stroke="#F26D6B" stroke-width="2"/>
                          </svg>
                        </div>
                        <div className="materials-item-title--text">Dedication</div>
                      </div>
                      <div className="materials-item-text">
                        The team will be dedicated to your company.
                        The experts will be embedded into your development process.
                        They will report and communicate to you directly.
                        They will also comply with your workflows, management style, and technical platforms.
                      </div>
                    </div>
                    <div className="materials-item">
                      <div className="materials-item-title">
                        <div className="materials-item-title--img">
                          <svg width="28" height="30" viewBox="0 0 28 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M26.1418 14.5046L25.7466 5.142C25.7466 4.99685 25.718 4.85313 25.6625 4.71903C25.6069 4.58493 25.5255 4.46309 25.4229 4.36046L22.3861 1.32371C22.2835 1.22108 22.1616 1.13967 22.0275 1.08413C21.8934 1.02858 21.7497 0.999996 21.6046 1H5.57303C5.42789 0.999998 5.28416 1.02858 5.15007 1.08413C5.01597 1.13967 4.89413 1.22108 4.79149 1.32371L1.75475 4.36046C1.65212 4.46309 1.5707 4.58493 1.51516 4.71903C1.45961 4.85313 1.43102 4.99685 1.43102 5.142L1.03579 14.5046C0.244064 21.6302 12.8427 29 13.5888 29C14.3349 29 26.9336 21.6302 26.1418 14.5046Z" stroke="#F26D6B" stroke-width="2"/>
                            <path d="M25.7466 5.142L26.1418 14.5046C26.9336 21.6302 14.3349 29 13.5888 29V1H21.6046C21.7497 0.999996 21.8935 1.02858 22.0275 1.08413C22.1616 1.13967 22.2835 1.22108 22.3861 1.32371L25.4229 4.36046C25.5255 4.46309 25.6069 4.58493 25.6625 4.71903C25.718 4.85313 25.7466 4.99685 25.7466 5.142Z" fill="#F26D6B"/>
                          </svg>
                        </div>
                        <div className="materials-item-title--text">Administration</div>
                      </div>
                      <div className="materials-item-text">
                        We take care of recruitment, infrastructure, administrative costs, vacation, and sick leaves that save a lot of your money.
                      </div>
                    </div>
                    <div className="materials-item">
                      <div className="materials-item-title">
                        <div className="materials-item-title--img">
                          <svg width="28" height="26" viewBox="0 0 28 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14 1L17.8206 8.74139L26.3637 9.98278L20.1819 16.0086L21.6412 24.5172L14 20.5L6.35879 24.5172L7.81813 16.0086L1.63627 9.98278L10.1794 8.74139L14 1Z" stroke="#F26D6B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                          </svg>
                        </div>
                        <div className="materials-item-title--text">experts</div>
                      </div>
                      <div className="materials-item-text">
                        <div className="materials-item-text">
                          We call ourselves experts. And we:
                        </div>
                        <ul className="list">
                          <li className="list-item">always act proactively</li>
                          <li className="list-item">always look for business problems and learn about our clients' business to come up with proper solutions</li>
                          <li className="list-item">have both hard and soft skills as well as experience, knowledge, and passion to make our clients' business better.</li>
                        </ul>
                      </div>
                    </div>
                    <div className="materials-item">
                      <div className="materials-item-title">
                        <div className="materials-item-title--img">
                          <svg width="35" height="32" viewBox="0 0 35 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M23.2543 17.6109C23.6728 17.6109 24.0493 17.2343 24.0493 16.8159V14.8912H27.8569C29.4887 14.8912 30.8276 13.5523 30.8276 11.9205V9.5774C32.8359 9.20084 34.3841 7.44351 34.3841 5.30962C34.3841 2.92469 32.4594 1 30.0744 1C27.6895 1 25.7648 2.92469 25.7648 5.30962C25.7648 7.44351 27.2711 9.20084 29.2794 9.5774V11.9205C29.2794 12.6736 28.6518 13.3013 27.8987 13.3013H24.0912V11C24.0912 10.5816 23.7146 10.205 23.2962 10.205H11.246C10.8276 10.205 10.451 10.5816 10.451 11V13.2594H6.64347C5.01167 13.2594 3.67275 14.5983 3.67275 16.2301V18.4059C1.66439 18.7824 0.199951 20.5397 0.199951 22.6736C0.199951 25.0586 2.12464 26.9833 4.50957 26.9833C6.89451 26.9833 8.8192 25.0586 8.8192 22.6736C8.8192 20.5397 7.27108 18.7824 5.22087 18.4059V16.272C5.22087 15.5188 5.84849 14.8912 6.60162 14.8912H10.4092V16.8577C10.4092 17.2761 10.7857 17.6527 11.2041 17.6527H16.4343V24.9331C16.4343 26.5649 17.7732 27.9038 19.405 27.9038H20.4928C20.7857 29.6611 22.3338 31 24.133 31H28.8192C30.8694 31 32.543 29.3264 32.543 27.2761C32.543 25.2259 30.8694 23.5523 28.8192 23.5523H24.2167C22.5012 23.5523 21.0368 24.7238 20.6184 26.3138H19.4468C18.6937 26.3138 18.0661 25.6862 18.0661 24.9331V17.6109H23.2543ZM27.3129 5.30962C27.3129 3.80335 28.5263 2.54812 30.0744 2.54812C31.5807 2.54812 32.8359 3.76151 32.8359 5.30962C32.8359 6.85774 31.6225 8.07113 30.0744 8.07113C28.5263 8.07113 27.3129 6.8159 27.3129 5.30962ZM7.22924 22.6736C7.22924 24.1799 6.01585 25.4351 4.46773 25.4351C2.96146 25.4351 1.70623 24.2218 1.70623 22.6736C1.70623 21.1674 2.91962 19.9121 4.46773 19.9121C6.01585 19.9121 7.22924 21.1674 7.22924 22.6736ZM24.2167 25.1004H28.9029C30.0744 25.1004 31.0368 26.0628 31.0368 27.2343C31.0368 28.4059 30.0744 29.3682 28.9029 29.3682H24.2167C23.0451 29.3682 22.0828 28.4059 22.0828 27.2343C22.0828 26.0628 23.0451 25.1004 24.2167 25.1004ZM12.041 11.7531H22.4594V16.0209H12.041V11.7531Z" fill="#F26D6B" stroke="#F26D6B" stroke-width="0.4"/>
                          </svg>
                        </div>
                        <div className="materials-item-title--text">Workflows</div>
                      </div>
                      <div className="materials-item-text">
                        <div className="materials-item-text">
                          Our experts can help your team setting up workflows:
                        </div>
                        <ul className="list">
                          <li className="list-item">We can set up an Agile development process</li>
                          <li className="list-item">We can set up CI/CD</li>
                          <li className="list-item">We can teach how to write automated tests</li>
                          <li className="list-item">We can help with git-flow and code review etc.</li>
                        </ul>
                      </div>
                    </div>
                    <div className="materials-item">
                      <div className="materials-item-title">
                        <div className="materials-item-title--img">
                          <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.47541 14.6955C8.41176 14.6955 9.17082 13.9365 9.17082 13.0001C9.17082 12.0637 8.41176 11.3047 7.47541 11.3047C6.53906 11.3047 5.78 12.0637 5.78 13.0001C5.78 13.9365 6.53906 14.6955 7.47541 14.6955Z" fill="#F26D6B"/>
                            <path d="M12.9999 11.3044C12.6645 11.3044 12.3367 11.4039 12.0579 11.5901C11.7791 11.7764 11.5618 12.0412 11.4334 12.351C11.3051 12.6608 11.2715 13.0017 11.3369 13.3306C11.4023 13.6595 11.5638 13.9616 11.8009 14.1987C12.038 14.4358 12.3401 14.5973 12.669 14.6627C12.9979 14.7282 13.3388 14.6946 13.6486 14.5663C13.9584 14.438 14.2232 14.2206 14.4095 13.9418C14.5958 13.663 14.6952 13.3352 14.6952 12.9999C14.6952 12.5502 14.5166 12.119 14.1987 11.801C13.8807 11.4831 13.4495 11.3045 12.9999 11.3044Z" fill="#F26D6B"/>
                            <path d="M18.5244 14.6955C19.4607 14.6955 20.2198 13.9365 20.2198 13.0001C20.2198 12.0637 19.4607 11.3047 18.5244 11.3047C17.588 11.3047 16.8289 12.0637 16.8289 13.0001C16.8289 13.9365 17.588 14.6955 18.5244 14.6955Z" fill="#F26D6B"/>
                            <path d="M13.0011 0C9.55445 0.00358598 6.25 1.37418 3.81267 3.81109C1.37534 6.248 0.00417948 9.55222 2.17912e-06 12.9988C-0.00117164 15.0158 0.471897 17.0048 1.38102 18.8053L1.50553 19.0539L0.39438 25.9415L0.534592 25.9423C0.639635 25.9429 11.0498 26 13.0011 26C16.4489 26 19.7555 24.6304 22.1934 22.1924C24.6314 19.7544 26.001 16.4478 26.001 13C26.001 9.55219 24.6314 6.24559 22.1934 3.80762C19.7555 1.36965 16.4489 1.04071e-05 13.0011 1.04071e-05V0ZM12.9987 23.7191C11.6801 23.7191 6.38552 23.6915 3.22207 23.675L3.07248 23.6742L3.86874 18.7298L3.87507 18.6916L3.42067 17.783C2.67077 16.2998 2.28033 14.6609 2.28091 12.9988C2.28115 10.8788 2.91004 8.80642 4.08807 7.0438C5.26609 5.28118 6.94034 3.90747 8.8991 3.09639C10.8578 2.28531 13.0131 2.07328 15.0924 2.48711C17.1716 2.90095 19.0815 3.92206 20.5804 5.42133C22.0793 6.9206 23.1 8.83068 23.5134 10.91C23.9267 12.9894 23.7142 15.1446 22.9027 17.1032C22.0911 19.0617 20.717 20.7357 18.9541 21.9133C17.1913 23.0909 15.1187 23.7193 12.9987 23.7191Z" fill="#F26D6B"/>
                          </svg>
                        </div>
                        <div className="materials-item-title--text">Regular feedback</div>
                      </div>
                      <div className="materials-item-text">
                        We organize regular feedback meetings with our clients to find out if everything is up to standards and ensure that the delivery continues in a productive and expeditious way.
                      </div>
                    </div>
                    <div className="materials-item">
                      <div className="materials-item-title">
                        <div className="materials-item-title--img">
                          <svg width="26" height="33" viewBox="0 0 26 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="1" y="2" width="24" height="30" rx="2" stroke="#F26D6B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            <rect x="6" width="14" height="4" rx="1" fill="#F26D6B"/>
                            <path d="M7 11H19" stroke="#F26D6B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M7 17H19" stroke="#F26D6B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M7 23H19" stroke="#F26D6B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                          </svg>
                        </div>
                        <div className="materials-item-title--text">Supervising</div>
                      </div>
                      <div className="materials-item-text">
                        Internal status reports with our experts ensure that the delivery is supervised and that we can react proactively before small problems grow into a bigger problem.
                      </div>
                    </div>
                    <div className="materials-item">
                      <div className="materials-item-title">
                        <div className="materials-item-title--img">
                          <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="13" cy="13" r="12" stroke="#F26D6B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="4 4"/>
                            <path d="M13 5V13L17 17" stroke="#F26D6B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                          </svg>
                        </div>
                        <div className="materials-item-title--text">Flexible working hours</div>
                      </div>
                      <div className="materials-item-text">
                        We always tend to find a way to work in a client's timezone so communication between remote teams loses no quality.
                      </div>
                    </div>
                    <div className="materials-item">
                      <div className="materials-item-title">
                        <div className="materials-item-title--img">
                          <svg width="32" height="28" viewBox="0 0 32 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14.104 15.2697L10.7344 26.0044C10.6282 26.3428 10.3512 26.6098 9.99808 26.6424C8.30033 26.7992 7.03666 25.8016 7.17361 24.0212L7.83124 10.7169L2.38967 6.23807C1.64886 5.62833 1.01153 4.82094 1.00026 3.86154C0.98408 2.48488 1.72795 1.52047 3.06413 1.1584C4.03387 0.895632 5.02663 1.32257 5.83607 1.91775L11.4735 6.06291C13.9017 5.30411 23.1085 2.06656 24.9296 2.01597C26.3903 1.97171 27.1925 2.93441 27.4042 4.22633C27.4617 4.5776 27.2734 4.91446 26.9717 5.10339L17.4427 11.071C19.8348 13.2717 22.2722 15.4725 24.6692 17.6732C24.9254 17.9084 25.2853 17.994 25.6198 17.8989L29.4342 16.8149C29.719 16.7339 30.0254 16.793 30.2597 16.9741V16.9741C30.6682 17.2897 30.7427 17.8772 30.4259 18.2848L24.3371 26.1193C24.0215 26.5255 23.4367 26.5996 23.0297 26.2851V26.2851C22.7932 26.1023 22.6585 25.8173 22.6676 25.5184L22.7879 21.5486C22.7985 21.1993 22.6259 20.8699 22.333 20.6793C19.6067 18.9046 16.878 17.0871 14.104 15.2697Z" stroke="#F26D6B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                          </svg>
                        </div>
                        <div className="materials-item-title--text">On-site travels</div>
                      </div>
                      <div className="materials-item-text">
                        The best way to get to know a client’s business is to have an on-site visit. It gives an incredible immersion and experience which helps a lot to understand all your problems and needs.
                      </div>
                    </div>
                  </div>
                </Panel>
                <Panel header="USE" className="custom-collapse-panel last" key="3" showArrow={false}>
                  <div className="materials">
                    <div className="materials-item">
                      <div className="materials-item-text">
                        <ul className="list">
                          <li className="list-item">You want to add more workforce to your existing in-house R&D team to boost up your development but you can not find in-house experts</li>
                          <li className="list-item">You need direct and straightforward communication with each team member</li>
                          <li className="list-item">You need fast scalability and flexibility for your team</li>
                          <li className="list-item">You have long-term IT projects with original goals and rapidly changing scopes</li>
                          <li className="list-item">You need full control over the development process</li>
                          <li className="list-item">You want to access cross-border high-quality expertise and knowledge sharing to support the exchange of ideas and diversify the skills of your in-house R&D team</li>
                          <li className="list-item">You want to reduce development costs as much as possible.</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </Panel>
              </Collapse>}
            </div>
          </div>
        </div>
        {
          <div className="horizontal-slide__right-side time-and-materials-contacts">
            <ContactUsBlock title="Contact Us?" formName="time-and-materials_form" buttonText="Send message" descriptionFieldTitle="Tell us your problem" attachmentButton="Add document"/>
          </div>
        }
      </div>
    </section>
  );};

export default TimeAndMaterialsPage;
