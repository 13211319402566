import React, { useState, useEffect } from 'react';
import './styles.scss';
import {useMediaQuery} from 'react-responsive/src';

let pageHeight = null;

const Menu = () => {
    const isDesktop = useMediaQuery({query: '(min-width: 840px)'});
    const [showedMenu, setShowedMenu] = useState(!isDesktop ? 1 : 0);

    const scrolling = () => {
        if (!isDesktop) {
            let menu = 1;
            let domainsBlock = document.getElementById('ourcases') !== null ? document.getElementById('ourcases').offsetHeight : 0;
            let howWeWorkBlock = document.getElementById('howwework') !== null ? document.getElementById('howwework').offsetHeight : 0;
            let careerBlock = document.getElementById('company') !== null ? document.getElementById('company').offsetHeight : 0;

            menu = window.scrollY >= pageHeight - 50 ? 2 : menu;
            menu = window.scrollY >= pageHeight + domainsBlock - 50 ? 3 : menu;
            menu = window.scrollY >= pageHeight + domainsBlock + howWeWorkBlock - 50 ? 4 : menu;
            menu = window.scrollY >= pageHeight + domainsBlock + howWeWorkBlock + careerBlock - 50 ? 5 : menu;

            setShowedMenu(menu);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', scrolling);
        pageHeight = !pageHeight ? window.innerHeight : pageHeight;
        scrolling();
        return () => {
            window.removeEventListener('scroll', scrolling);
        };
    }, []);

    return (
        <div className="menu-wrapper">
            <ul className="menu-list">
                <li className={`menu-item ${showedMenu === 1 ? 'active' : ''}`} data-menuanchor="whoweare"><a href={'#whoweare'}>who we are</a></li>
                <li className={`menu-item ${showedMenu === 2 ? 'active' : ''}`} data-menuanchor="ourcases"><a href={'#ourcases'}>our cases</a></li>
                <li className={`menu-item ${showedMenu === 3 ? 'active' : ''}`} data-menuanchor="howwework"><a href={'#howwework'}>how we work</a></li>
                <li className={`menu-item ${showedMenu === 4 ? 'active' : ''}`} data-menuanchor="company"><a href={'#company'}>company</a></li>
                <li className={`menu-item ${showedMenu === 5 ? 'active' : ''}`} data-menuanchor="contacts"><a href={'#contacts'}>contacts</a></li>
            </ul>
        </div>
    );
};

export default Menu;
