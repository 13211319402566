import React, {useRef, useEffect, useState} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import ContactUsBlock from '../../components/ContactUsBlock/ContactUsBlock';
import {useMediaQuery} from 'react-responsive';
import './styles.scss';
import vacancies from "../../assets/data/vacancy.json";


const VacancyPage = (props) => {
  let { id } = useParams();
  const isDesktop = useMediaQuery({ query: '(min-width: 768px)' });
  const [vacancy, setVacancy] = useState(vacancies.find(x => x.id == id));
  const myRef = useRef(null);
  const history = useHistory();
  const [isHovered, setIsHovered] = useState(false);

  const toggleHover = () => {
    setIsHovered(prevState => !prevState);
  };

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    setVacancy(vacancies.find(x => x.id == id));
    init();
  }, [id]);

  const init = () => {
    if (!isDesktop)
      window.scrollTo(0, 0);
  }

  const executeScroll = () => myRef.current.scrollIntoView();

  const onBackClick = () => {
    if (isDesktop)
      history.push('/#company');
    else 
      history.goBack();
  };

  const moveToVacancy = (index, e) => {
    e.stopPropagation();
    e.preventDefault();
    setVacancy(vacancies.find(x => x.id == index));
    executeScroll();
    history.push('/company/vacancy/' + index);
    return false;
  };

  return (
    <section className="content static">
      <div className="horizontal-slide vacancy" ref={myRef}>
        <div className="back" onClick={onBackClick} onMouseEnter={toggleHover} onMouseLeave={toggleHover}>
          <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.0312 7.01562C15.5749 7.01562 16.0156 7.45634 16.0156 8C16.0156 8.54366 15.5749 8.98438 15.0312 8.98438H3.82812L8.70754 13.9048C9.0939 14.2944 9.09258 14.923 8.7046 15.311C8.31546 15.7002 7.68454 15.7002 7.2954 15.311L0.691481 8.70711C0.300957 8.31658 0.300958 7.68342 0.691482 7.29289L7.2954 0.688971C7.68454 0.299833 8.31546 0.299833 8.7046 0.688971C9.09258 1.07696 9.0939 1.7056 8.70754 2.09521L3.82812 7.01562H15.0312Z" fill={!isHovered ? '#fff' : '#F26D6B'}/>
          </svg>
        </div>
        <div className="horizontal-slide__left-side vacancy-info">
          <div className="vacancy-info-content">
            <h1 className="vacancy-info__title">{vacancy.title}</h1>
            <div className="vacancy-info__list">
              <div className="title">Requirements</div>
              <ul className="list">
                {vacancy.requirements.map(x => <li className="list-item">{x}</li>)}
              </ul>
            </div>
            <div className="vacancy-info__list">
              <div className="title">Official duties</div>
              <ul className="list">
                {vacancy['official duties'].map(x => <li className="list-item">{x}</li>)}
              </ul>
            </div>
          </div>
          <div className="vacancy-info__list gray">
            <div className="vacancy-info__list--full">
              <div className="title">Working conditions at <span className="pink">Spark</span></div>
              <ul className="list">
                {vacancy['workingconditions at spark'].map(x => <li className="list-item">{x}</li>)}
              </ul>
            </div>
          </div>
          <div className="positions article">
            <div className="article-icon">
              <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="24" cy="24" r="23" stroke="#F26D6B" strokeWidth="2"/>
                <circle cx="24" cy="24" r="23" stroke="#F26D6B" strokeWidth="2"/>
                <path d="M19 11H29L28 23H20L19 11Z" stroke="#F26D6B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M13.5 17.5C19.5 17.5 14.5 27 19 27C22.6 27 23.8333 27 24 27" stroke="#F26D6B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M34.5 17.5C28.5 17.5 33.5 27 29 27C25.4 27 24.1667 27 24 27" stroke="#F26D6B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M24 27V34" stroke="#F26D6B" strokeWidth="2"/>
                <path d="M29 34V33C29 31.8954 28.1046 31 27 31H21C19.8954 31 19 31.8954 19 33V34" stroke="#F26D6B" strokeWidth="2"/>
                <circle cx="29" cy="35" r="2" fill="#F26D6B"/>
                <circle cx="24" cy="35" r="2" fill="#F26D6B"/>
                <circle cx="19" cy="35" r="2" fill="#F26D6B"/>
              </svg>
            </div>
            <div className="article-content">
              <div className="article-header">
                Open Positions
              </div>
              <div className="article-text">
                <ul className="positions-list">
                  {vacancies.map((x, i) => <li onClick={(event)=>moveToVacancy(x.id, event)}>
                    <a href={`/company/vacancy/${x.id}`}>{x.title}</a>
                  </li>)}
                </ul>
              </div>
            </div>

          </div>
        </div>
        <div className="horizontal-slide__right-side vacancy-contacts">
          <ContactUsBlock title="Are you interested?" formName="vacancy_1_form" buttonText="Apply for this job"/>
        </div>
      </div>
    </section>
);};

export default VacancyPage;
