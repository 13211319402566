import React, {useState} from 'react';
import { useHistory } from 'react-router-dom';
import MyMapComponent from '../../components/Map';
import './contacts.scss';
import { Button } from 'antd';
import {useMediaQuery} from 'react-responsive/src';

const Contacts = (props) => {
  const isDesktop = useMediaQuery({ query: '(min-width: 840px)' });
  const history = useHistory();

  const moveToDetails = (e) => {
    e.stopPropagation();
    e.preventDefault();
    history.push('/contacts/contactus');
    return false;
  };

  return(
    <div id={isDesktop ? "" : "contacts"} className="layout white section fp-auto-height-responsive">
      <section className="slide content" style={{height: '100%'}}>
        <h1 className="hidden">Contacts</h1>
        <div className="contacts">
          {isDesktop && <div className="info-block">
            <div className="address">
              <div className="street">
                P.O.Box 4422
              </div>
              <div className="floor">
                Creative Tower
              </div>
            </div>
            <div className="main-info">
              <div className="izhevsk">Fujairah</div>
              <div className="email">
                <a href="mailto:info@spark.run" target="_blank" rel="noopener noreferrer">info@spark.run</a>
              </div>
              <div className="phone">
                <a href="tel:+971 50 692 3310">+971 50 692 3310</a>
              </div>
            </div>
            <div className="work-time">
              <a href={`/contacts/contactus`}>
                <Button onClick={moveToDetails} className="work-time--btn">
                  CONTACT US
                </Button>
              </a>
            </div>
          </div>}
          {!isDesktop && <div className="info-block">
            <div className="main-info">
              <div className="izhevsk">Fujairah</div>
              <div className="email">
                <a href="mailto:info@spark.run" target="_blank" rel="noopener noreferrer">info@spark.run</a>
              </div>
              <div className="phone">
                <a href="tel:+971 50 692 3310">+971 50 692 3310</a>
              </div>
            </div>
            <div className="address">
              <div className="street">
                P.O.Box 4422
              </div>
              <div className="floor">
                Creative Tower
              </div>
            </div>
            <div className="work-time">
              <a href={`/contacts/contactus`}>
                <Button onClick={moveToDetails} className="work-time--btn">CONTACT US</Button>
              </a>
            </div>
          </div>}
          <div className="map" id="map">
            <MyMapComponent/>
          </div>
          { !isDesktop && <div className="contacts-footer">
            <div>
              <div className="contacts-footer__block">
                <div className="contacts-footer--img">
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.86375 15.999C0.752186 15.999 0.63975 15.9772 0.533416 15.9328C0.210054 15.7985 0 15.4839 0 15.1335V0.864623C0 0.386988 0.386988 0 0.864622 0H15.1344C15.4839 0 15.7994 0.210926 15.9336 0.533416C16.0679 0.856778 15.9938 1.22808 15.7462 1.47561L1.47561 15.7462C1.31001 15.9118 1.08862 15.999 0.86375 15.999ZM1.72837 1.72925V13.0469L13.046 1.72925H1.72837Z" fill="#F26D6B"/>
                    <path d="M15.1363 2.00272e-05C15.2478 2.00272e-05 15.3602 0.0218096 15.4666 0.0662613C15.7899 0.200487 16 0.515133 16 0.865514V15.1344C16 15.612 15.613 15.999 15.1354 15.999H0.865624C0.516115 15.999 0.200597 15.7881 0.066371 15.4656C-0.0678558 15.1422 0.00623035 14.7709 0.253763 14.5234L14.5244 0.252783C14.69 0.0871792 14.9114 2.00272e-05 15.1363 2.00272e-05ZM14.2716 14.2698V2.95211L2.95397 14.2698H14.2716Z" fill="#F26D6B"/>
                  </svg>
                </div>
                <div className="contacts-footer--red-text">Download logo guidelines</div>
              </div>
              <div className="contacts-footer--grey-text"><a target="_blank" className="link" href="https://www.figma.com/file/OakxQfLzZptfT4I1mLH8uw/Untitled?node-id=0%3A1">Figma file</a></div>
            </div>
            <div>
              <div className="contacts-footer__block">
                <div className="contacts-footer--img">
                  <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.83017 15.9999C1.67851 15.9999 1.52511 15.9598 1.38566 15.8761C0.976007 15.6303 0.843525 15.0995 1.08932 14.6899L4.50684 8.99486C4.51207 8.98615 4.5173 8.97656 4.5234 8.96784L9.65187 0.420109C9.80788 0.159502 10.0894 0 10.3936 0C10.6978 0 10.9784 0.159502 11.1353 0.420109L19.697 14.6899C19.8888 15.0097 19.8539 15.4176 19.6098 15.7C19.3658 15.9824 18.9675 16.0766 18.6232 15.9328L5.61377 10.5123L2.57364 15.5798C2.40978 15.85 2.1239 15.9999 1.83017 15.9999ZM6.51151 9.01317L16.86 13.3249L10.3927 2.54593L6.51151 9.01317Z" fill="#F26D6B"/>
                  </svg>
                </div>
                <div className="contacts-footer--red-text">Write directly to ceo</div>
              </div>
              <div className="contacts-footer--grey-text ceo"><a href="mailto:ceo@spark.run">ceo@spark.run</a></div>
            </div>
          </div>}
          {
            isDesktop && <div className="contacts-footer">
              <div>
                <div className="contacts-footer__block">
                  <div className="contacts-footer--img">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0.86375 15.999C0.752186 15.999 0.63975 15.9772 0.533416 15.9328C0.210054 15.7985 0 15.4839 0 15.1335V0.864623C0 0.386988 0.386988 0 0.864622 0H15.1344C15.4839 0 15.7994 0.210926 15.9336 0.533416C16.0679 0.856778 15.9938 1.22808 15.7462 1.47561L1.47561 15.7462C1.31001 15.9118 1.08862 15.999 0.86375 15.999ZM1.72837 1.72925V13.0469L13.046 1.72925H1.72837Z" fill="#F26D6B"/>
                      <path d="M15.1363 2.00272e-05C15.2478 2.00272e-05 15.3602 0.0218096 15.4666 0.0662613C15.7899 0.200487 16 0.515133 16 0.865514V15.1344C16 15.612 15.613 15.999 15.1354 15.999H0.865624C0.516115 15.999 0.200597 15.7881 0.066371 15.4656C-0.0678558 15.1422 0.00623035 14.7709 0.253763 14.5234L14.5244 0.252783C14.69 0.0871792 14.9114 2.00272e-05 15.1363 2.00272e-05ZM14.2716 14.2698V2.95211L2.95397 14.2698H14.2716Z" fill="#F26D6B"/>
                    </svg>
                  </div>
                  <div className="contacts-footer--red-text">Download logo guidelines</div>
                </div>
                <div className="contacts-footer--grey-text"><a target="_blank" className="link" href="https://www.figma.com/file/OakxQfLzZptfT4I1mLH8uw/Untitled?node-id=0%3A1">Figma file</a></div>

              </div>
              <div>
                <div className="contacts-footer--red-text contacts-footer__block">
                  <div className="contacts-footer--img">
                    <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1.83017 15.9999C1.67851 15.9999 1.52511 15.9598 1.38566 15.8761C0.976007 15.6303 0.843525 15.0995 1.08932 14.6899L4.50684 8.99486C4.51207 8.98615 4.5173 8.97656 4.5234 8.96784L9.65187 0.420109C9.80788 0.159502 10.0894 0 10.3936 0C10.6978 0 10.9784 0.159502 11.1353 0.420109L19.697 14.6899C19.8888 15.0097 19.8539 15.4176 19.6098 15.7C19.3658 15.9824 18.9675 16.0766 18.6232 15.9328L5.61377 10.5123L2.57364 15.5798C2.40978 15.85 2.1239 15.9999 1.83017 15.9999ZM6.51151 9.01317L16.86 13.3249L10.3927 2.54593L6.51151 9.01317Z" fill="#F26D6B"/>
                    </svg>
                  </div>
                  <div>Write directly to ceo</div>
                </div>
                <div className="contacts-footer--grey-text ceo"><a href="mailto:ceo@spark.run">ceo@spark.run</a></div>
              </div>
            </div>
          }
        </div>
      </section>
    </div>
  );
};
export default Contacts;
